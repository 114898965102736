<template>
  <v-card class="pa-5">
    <v-btn
      class="close-button"
      icon
      @click="$emit('input', false)"
      color="black"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-row class="px-6" justify="space-around">
      <v-col cols="12" class="pa-0"
        ><p id="titleText" class="text-center text-h4 primary--text">
          {{ $t("analytics.burnout.detailsDialog.title") }}
        </p></v-col
      >
      <v-col
        cols="12"
        class="mb-8 text-center"
        :class="$vuetify.breakpoint.smAndDown ? 'px-2' : ' px-12'"
        >{{ $t("analytics.burnout.detailsDialog.explanation") }}
      </v-col>
      <v-col cols="8" sm="4" lg="2" class="my-2"
        ><BurnoutDonut
          :title="$t('burnoutSchema.cognitiveImpairment.title')"
          :values="getNumberOfPeopleInEachLevel('exhaustion')"
          :toolTip="
            $t('burnoutReportDetailsCards.cognitiveimpairment.description')
          "
      /></v-col>
      <v-col cols="8" sm="4" lg="2" class="my-2"
        ><BurnoutDonut
          :title="$t('burnoutSchema.mentalDistance.title')"
          :values="getNumberOfPeopleInEachLevel('mentalDistance')"
          :toolTip="$t('burnoutReportDetailsCards.mentaldistance.description')"
      /></v-col>
      <v-col cols="8" sm="4" lg="2" class="my-2"
        ><BurnoutDonut
          :title="$t('burnoutSchema.exhaustion.title')"
          :values="getNumberOfPeopleInEachLevel('cognitiveImpairment')"
          :toolTip="$t('burnoutReportDetailsCards.exhaustion.description')"
      /></v-col>
      <v-col cols="8" sm="4" lg="2" class="my-2"
        ><BurnoutDonut
          :title="$t('burnoutSchema.emotionalImpairment.title')"
          :values="getNumberOfPeopleInEachLevel('emotionalImpairment')"
          :toolTip="
            $t('burnoutReportDetailsCards.emotionalimpairment.description')
          "
      /></v-col>
      <v-col cols="8" sm="4" lg="2" class="my-2"
        ><BurnoutDonut
          :title="$t('burnoutSchema.secondarySymptoms.title')"
          :values="getNumberOfPeopleInEachLevel('secondarySymptoms')"
          :toolTip="
            $t('burnoutReportDetailsCards.secondarysymptoms.description')
          "
      /></v-col>
      <v-col cols="10">
        <v-row class="text-center" justify="center"
          ><v-col cols="6" md="3" lg="2"
            ><span class="dot mr-2" :style="getLevelColor('low')" />{{
              $t("burnoutReportSummary.severity.low")
            }}</v-col
          >
          <v-col cols="6" md="3" lg="2"
            ><span class="dot mr-2" :style="getLevelColor('average')" />{{
              $t("burnoutReportSummary.severity.average")
            }}</v-col
          >
          <v-col cols="6" md="3" lg="2"
            ><span class="dot mr-2" :style="getLevelColor('high')" />{{
              $t("burnoutReportSummary.severity.high")
            }}</v-col
          ><v-col cols="6" md="3" lg="2"
            ><span class="dot mr-2" :style="getLevelColor('veryhigh')" />{{
              $t("burnoutReportSummary.severity.veryHigh")
            }}</v-col
          >
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import BurnoutDonut from "@/components/dashboard/analytics/analyticsCards/BurnoutCategoryDonut";
import CategoryValues from "@/assets/json/BurnoutAssessment/BurnoutAssessmentCategoryValues.json";

export default {
  components: { BurnoutDonut },
  props: {
    value: Boolean,
    scores: Object
  },
  methods: {
    getLevelColor(level) {
      let color;
      if (level === "low") {
        color = this.$vuetify.theme.currentTheme.vitruePaleGreen;
      } else if (level === "average") {
        color = this.$vuetify.theme.currentTheme.primary;
      } else if (level === "high") {
        color = this.$vuetify.theme.currentTheme.vitrueYellow;
      } else {
        color = this.$vuetify.theme.currentTheme.vitrueRed;
      }
      return {
        "--color": color
      };
    },
    getNumberOfPeopleInEachLevel(category) {
      let scoresName = category + "Scores";
      let categoryName = category.toLowerCase();
      let returnObject = { low: 0, average: 0, high: 0, veryhigh: 0 };
      this.scores[scoresName].forEach(s => {
        if (s >= CategoryValues[categoryName].veryhigh) {
          returnObject.veryhigh++;
        } else if (s >= CategoryValues[categoryName].high) {
          returnObject.high++;
        } else if (s >= CategoryValues[categoryName].average) {
          returnObject.average++;
        } else {
          returnObject.low++;
        }
      });
      return returnObject;
    }
  }
};
</script>

<style scoped>
.dot {
  height: 25px;
  width: 25px;
  background-color: var(--color);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 6px;
}
</style>
