<template>
  <v-row>
    <v-col cols="12" lg="4" class="d-flex flex-column py-0">
      <v-row v-if="$vuetify.breakpoint.md">
        <v-col cols="5" class="py-0">
          <overall-scores-card :summaryAnalytics="summaryData" />
        </v-col>
        <v-col cols="7" class="py-0">
          <screen-height-analytics :webcamAnalytics="webcamData" />
        </v-col>
      </v-row>
      <overall-scores-card
        v-if="!$vuetify.breakpoint.md"
        :summaryAnalytics="summaryData"
        class="mb-6"
      />
      <screen-height-analytics
        :webcamAnalytics="webcamData"
        v-if="!$vuetify.breakpoint.md"
      />
    </v-col>
    <v-col cols="12" lg="4" class="d-flex flex-column py-0">
      <pain-areas-card
        class="pa-0"
        :painAnalytics="painData"
        :class="$vuetify.breakpoint.mdAndDown ? 'my-6' : ''"
      />
    </v-col>
    <v-col cols="12" lg="4" class="d-flex flex-column py-0">
      <v-row no-gutters>
        <v-col cols="12"
          ><category-card
            class="mb-6 pa-0"
            :values="equipmentData ? equipmentData.simsScores : null"
        /></v-col>
      </v-row>

      <v-row v-if="$vuetify.breakpoint.md" class="mb-6">
        <v-col cols="6" class="d-flex flex-column py-0">
          <missing-equipment-list
            :equipment="equipmentData"
            class="flex-grow-1 pa-0"
          />
        </v-col>
        <v-col cols="6" class="d-flex flex-column py-0">
          <category-card
            v-if="burnout"
            class="flex-grow-1 pa-0"
            burnout
            :values="burnoutData ? burnoutData.overallScores : null"
            :detailedInformationValues="burnoutData"
          />
          <desk-wellness
            v-else
            :equipment="equipmentData"
            class="flex-grow-1 pa-0"
          />
        </v-col>
      </v-row>
      <missing-equipment-list
        :equipment="equipmentData"
        class="mb-6 pa-0"
        v-if="!$vuetify.breakpoint.md"
      />
      <category-card
        class="flex-grow-1 pa-0"
        v-if="!$vuetify.breakpoint.md && burnout"
        burnout
        :values="burnoutData ? burnoutData.overallScores : null"
        :detailedInformationValues="burnoutData"
      />
      <desk-wellness
        :equipment="equipmentData"
        class="flex-grow-1 pa-0"
        v-else-if="!$vuetify.breakpoint.md"
      />
    </v-col>
  </v-row>
</template>

<script>
import MissingEquipmentList from "./analyticsCards/MissingEquipmentList.vue";
import ScreenHeightAnalytics from "./analyticsCards/ScreenHeightAnalytics.vue";
import OverallScoresCard from "./analyticsCards/OverallScoresCard.vue";
import PainAreasCard from "./analyticsCards/PainAreasCard.vue";
import CategoryCard from "./analyticsCards/CategoryCard.vue";
import DeskWellness from "./analyticsCards/DeskWellness.vue";
import {
  getEquipmentData,
  getBurnoutData,
  getPainData,
  getLatestSummaryData,
  getScreenHeightData
} from "@/customApi";
import { mapGetters } from "vuex";

export default {
  name: "Overview",
  components: {
    ScreenHeightAnalytics,
    MissingEquipmentList,
    OverallScoresCard,
    PainAreasCard,
    CategoryCard,
    DeskWellness
  },
  props: {
    filter: Object
  },
  data() {
    return {
      summaryData: null,
      webcamData: null,
      painData: null,
      equipmentData: null,
      burnoutData: null
    };
  },
  computed: {
    ...mapGetters(["burnout"]),
    filterDto() {
      var fromDate = this.getDate(this.filter.timeFrame);
      var tags = this.filter.tags.map(x => x.name);
      return {
        FromDate: fromDate,
        Tags: tags,
        Locations: this.filter.locations
      };
    }
  },
  async mounted() {
    this.getAnalyticsForCards();
  },
  watch: {
    filter: {
      handler() {
        this.resetData();
        this.getAnalyticsForCards();
      },
      deep: true
    }
  },
  methods: {
    async getAnalyticsForCards() {
      this.loadSummaryData();
      this.loadWebcamData();
      this.loadEquipmentData();
      this.loadBurnoutData();
      this.loadPainData();
    },
    async loadSummaryData() {
      try {
        this.summaryData = await getLatestSummaryData(this.filterDto);
        if (this.summaryData.totalAssessmentsComplete === 0) {
          this.$emit("insufficientAssessments");
        }
      } catch (err) {
        if (err.request.status === 0) {
          this.$emit("showError", "timeoutError");
        }
      }
    },
    async loadWebcamData() {
      try {
        this.webcamData = await getScreenHeightData(this.filterDto);
      } catch (err) {
        if (err.request.status === 0) {
          this.$emit("showError", "timeoutError");
        }
      }
    },
    async loadEquipmentData() {
      try {
        this.equipmentData = await getEquipmentData(this.filterDto);
      } catch (err) {
        if (err.request.status === 0) {
          this.$emit("showError", "timeoutError");
        }
      }
    },
    async loadBurnoutData() {
      try {
        this.burnoutData = await getBurnoutData(this.filterDto);
      } catch (err) {
        if (err.request.status === 0) {
          this.$emit("showError", "timeoutError");
        }
      }
    },
    async loadPainData() {
      try {
        this.painData = await getPainData(this.filterDto);
      } catch (err) {
        if (err.request.status === 0) {
          this.$emit("showError", "timeoutError");
        }
      }
    },
    resetData() {
      this.summaryData = null;
      this.webcamData = null;
      this.equipmentData = null;
      this.burnoutData = null;
      this.painData = null;
    },
    getDate(timeFrame) {
      let today = new Date();
      switch (timeFrame) {
        case "Past week":
          today.setDate(today.getDate() - 7);
          break;
        case "Past month":
          today.setMonth(today.getMonth() - 1);
          break;
        case "Past 3 months":
          today.setMonth(today.getMonth() - 3);
          break;
        case "Past 6 months":
          today.setMonth(today.getMonth() - 6);
          break;
        default:
          today = null;
      }
      return today;
    }
  }
};
</script>
