<template>
  <flip-card
    :height="$vuetify.breakpoint.smAndDown ? '500px' : '100%'"
    width="100%"
  >
    <template v-slot:front>
      <div class="fill-height d-flex flex-column px-8">
        <p class="text-center text-h5 vitrueGrey--text mt-6 px-3 mb-0">
          {{ $t("analytics.overallScores.title") }}
        </p>
        <v-row v-if="summaryAnalytics" align="center">
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-row justify="center">
              <nested-circular-progress
                :outer="summaryAnalytics.maximumDeskScore"
                :middle="summaryAnalytics.averageDeskScore"
                :inner="summaryAnalytics.minimumDeskScore"
              />
            </v-row>
          </v-col>
          <v-col :cols="scoreContainerColumns">
            <v-row>
              <v-tooltip
                bottom
                max-width="200"
                nudge-top="50"
                content-class="text-center"
                open-on-click
                color="transparent"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-col
                    class="text-center"
                    :cols="scoreColumns"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <p
                      class="text-h4 vitruePaleGreen--text mb-0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ summaryAnalytics.maximumDeskScore }}%
                    </p>
                    <p class="body-1 mb-0">
                      {{ $t("analytics.overallScores.highScore") }}
                    </p>
                  </v-col>
                </template>
                <vitrue-tooltip
                  :message="$t('analytics.overallScores.highScoreDescription')"
                />
              </v-tooltip>
              <v-tooltip
                bottom
                max-width="200"
                nudge-top="50"
                content-class="text-center"
                open-on-click
                color="transparent"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-col
                    class="text-center"
                    :cols="scoreColumns"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <p class="text-h4 primary--text mb-0">
                      {{ summaryAnalytics.averageDeskScore }}%
                    </p>
                    <p class="body-1 mb-0">
                      {{ $t("analytics.overallScores.averageScore") }}
                    </p>
                  </v-col>
                </template>
                <vitrue-tooltip
                  :message="
                    $t('analytics.overallScores.averageScoreDescription')
                  "
                />
              </v-tooltip>
              <v-tooltip
                bottom
                max-width="200"
                nudge-top="50"
                content-class="text-center"
                open-on-click
                color="transparent"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-col
                    class="text-center"
                    :cols="scoreColumns"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <p class="text-h4 vitrueOrange--text mb-0">
                      {{ summaryAnalytics.minimumDeskScore }}%
                    </p>
                    <p class="body-1 mb-0">
                      {{ $t("analytics.overallScores.lowestScore") }}
                    </p>
                  </v-col>
                </template>
                <vitrue-tooltip
                  :message="
                    $t('analytics.overallScores.lowestScoreDescription')
                  "
                />
              </v-tooltip>
              <v-col
                cols="6"
                class="text-center"
                v-if="$vuetify.breakpoint.smAndDown"
              >
                <p class="text-h4 primary--text mb-0">
                  {{ summaryAnalytics.totalAssessmentsComplete }}
                </p>
                <p class="body-1 mb-0">
                  {{ $t("analytics.overallScores.assessmentsComplete") }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="6"
            class="text-center"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <v-row>
              <v-col cols="12" align-self="end">
                <v-row justify="center">
                  <nested-circular-progress
                    :outer="summaryAnalytics.maximumDeskScore"
                    :middle="summaryAnalytics.averageDeskScore"
                    :inner="summaryAnalytics.minimumDeskScore"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" align-self="end">
                <p class="text-h4 primary--text mb-0">
                  {{ summaryAnalytics.totalAssessmentsComplete }}
                </p>
                <p class="body-1 mb-0">
                  {{ $t("analytics.overallScores.complete") }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align="center"
          v-if="!summaryAnalytics"
          class="fill-height"
        >
          <v-progress-circular
            id="loadingCircle"
            :size="50"
            :width="5"
            indeterminate
            color="disabled"
          >
          </v-progress-circular>
        </v-row>
      </div>
    </template>
    <template v-slot:back>
      <v-row justify="center" align="center" class="px-8 fill-height">
        <v-col cols="12">
          <p
            class="text-body-1"
            v-html="$t('analytics.overallScores.explanation')"
          />
        </v-col>
      </v-row>
    </template>
  </flip-card>
</template>

<script>
import NestedCircularProgress from "./NestedCircularProgress";
import FlipCard from "@/components/common/FlipCard.vue";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "OverallScoreCard",
  components: {
    NestedCircularProgress,
    FlipCard,
    VitrueTooltip
  },
  props: {
    summaryAnalytics: Object
  },
  data() {
    return { showInfo: false };
  },
  computed: {
    scoreContainerColumns() {
      return this.$vuetify.breakpoint.smAndDown ? 12 : 6;
    },
    scoreColumns() {
      return this.$vuetify.breakpoint.smAndDown ? 6 : 12;
    }
  }
};
</script>
