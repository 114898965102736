var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height py-4 px-6" },
    [
      _vm.insufficientAssessmentsDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.smAndDown ? "100%" : "500",
                persistent: ""
              },
              model: {
                value: _vm.insufficientAssessmentsDialog,
                callback: function($$v) {
                  _vm.insufficientAssessmentsDialog = $$v
                },
                expression: "insufficientAssessmentsDialog"
              }
            },
            [
              _c("insufficient-assessments-dialog", {
                attrs: {
                  tags: _vm.filter.tags.includes("Everyone")
                    ? []
                    : _vm.filter.tags,
                  timeFrame: _vm.filter.timeFrame
                },
                model: {
                  value: _vm.insufficientAssessmentsDialog,
                  callback: function($$v) {
                    _vm.insufficientAssessmentsDialog = $$v
                  },
                  expression: "insufficientAssessmentsDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.feedbackDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": _vm.$vuetify.breakpoint.smAndDown ? "100%" : "40%",
                persistent: ""
              },
              model: {
                value: _vm.feedbackDialog,
                callback: function($$v) {
                  _vm.feedbackDialog = $$v
                },
                expression: "feedbackDialog"
              }
            },
            [
              _c("feedback-dialog", {
                model: {
                  value: _vm.feedbackDialog,
                  callback: function($$v) {
                    _vm.feedbackDialog = $$v
                  },
                  expression: "feedbackDialog"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("feedback-button", {
        style: _vm.$vuetify.breakpoint.smAndDown ? "" : "top:50%;right: 0%",
        model: {
          value: _vm.feedbackDialog,
          callback: function($$v) {
            _vm.feedbackDialog = $$v
          },
          expression: "feedbackDialog"
        }
      }),
      _c(
        "v-row",
        { staticClass: "mx-2 mb-4", attrs: { justify: "space-between" } },
        [
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.lgAndUp ? "py-0" : "",
              attrs: { cols: "12", lg: "5" }
            },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "ml-4",
                  class: _vm.$vuetify.breakpoint.smAndDown ? "mb-2" : "",
                  attrs: { centered: _vm.$vuetify.breakpoint.smAndDown },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab",
                    {
                      staticClass: "text-h6",
                      class: _vm.tab === "analytics" ? "font-weight-bold" : "",
                      attrs: { href: "#analytics" }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("analytics.tabs.analytics")) + " "
                      )
                    ]
                  ),
                  _c(
                    "v-tab",
                    {
                      staticClass: "text-h6",
                      class: _vm.tab === "insights" ? "font-weight-bold" : "",
                      attrs: { href: "#insights" }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("analytics.tabs.actions")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "py-0",
              attrs: { cols: "12", lg: "7", "align-self": "end" }
            },
            [
              _vm.tab == "analytics"
                ? _c("filter-analytics", {
                    key: _vm._i18n.locale,
                    on: {
                      filter: function($event) {
                        _vm.filter = $event
                      },
                      error: function($event) {
                        return _vm.showError("genericError")
                      }
                    }
                  })
                : _vm._e(),
              _vm.tab == "insights"
                ? _c(
                    "v-row",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "no-gutters": "",
                        justify: _vm.$vuetify.breakpoint.smAndDown
                          ? "center"
                          : "end"
                      }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            disabled: !_vm.anonymousResults,
                            bottom: "",
                            color: "transparent"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn-toggle",
                                      {
                                        attrs: { mandatory: "" },
                                        model: {
                                          value: _vm.individualInsights,
                                          callback: function($$v) {
                                            _vm.individualInsights = $$v
                                          },
                                          expression: "individualInsights"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "white--text text-body-2 rounded-0",
                                                attrs: {
                                                  width: "150px",
                                                  color: _vm.individualInsights
                                                    ? "disabled"
                                                    : "secondary"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "insightsPage.toggle.team"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "white--text text-body-2 rounded-0",
                                                attrs: {
                                                  width: "150px",
                                                  color: _vm.individualInsights
                                                    ? "secondary"
                                                    : "disabled",
                                                  disabled: _vm.anonymousResults
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "insightsPage.toggle.individual"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1241259596
                          )
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: {
                              message: _vm.$t("anonymousResults.tooltip")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticStyle: { width: "100%" },
          attrs: { touchless: "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "elevation-0 pa-0 ma-2 fill-height",
              attrs: {
                color: "transparent",
                disabled: _vm.insufficientAssessmentsDialog
              }
            },
            [
              _c(
                "v-tab-item",
                {
                  key: 1,
                  staticClass: "elevation-0 pa-0 ma-0 my-4 fill-height",
                  attrs: { value: "analytics" }
                },
                [
                  _c("overview", {
                    class: _vm.insufficientAssessmentsDialog ? "blur" : "",
                    attrs: { filter: _vm.filter },
                    on: {
                      showError: function($event) {
                        return _vm.showError($event)
                      },
                      insufficientAssessments: function($event) {
                        _vm.insufficientAssessmentsDialog = true
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            { key: 2, attrs: { value: "insights" } },
            [_c("insights", { attrs: { team: !_vm.individualInsights } })],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { id: "errorMessage", timeout: "-1", color: "red" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { dark: "", text: "" },
                        on: {
                          click: function($event) {
                            _vm.error = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.error,
            callback: function($$v) {
              _vm.error = $$v
            },
            expression: "error"
          }
        },
        [
          _c("p", {
            staticClass: "text-center mb-0",
            domProps: { innerHTML: _vm._s(_vm.errorMessage) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }