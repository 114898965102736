var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { width: "100%", height: "100%" }, attrs: { fluid: "" } },
    [
      _vm.detailedList
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: _vm.detailedListWidth },
              model: {
                value: _vm.detailedList,
                callback: function($$v) {
                  _vm.detailedList = $$v
                },
                expression: "detailedList"
              }
            },
            [
              _c("detailed-list-dialog", {
                attrs: {
                  title: _vm.$t("analytics.painAreasCard.popup.title"),
                  valueHeader: _vm.$t(
                    "analytics.painAreasCard.popup.valueHeader"
                  ),
                  listOfItems: _vm.detailedListItems
                },
                model: {
                  value: _vm.detailedList,
                  callback: function($$v) {
                    _vm.detailedList = $$v
                  },
                  expression: "detailedList"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("flip-card", {
        attrs: { height: _vm.cardHeight, width: "100%" },
        scopedSlots: _vm._u([
          {
            key: "front",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "fill-height d-flex flex-column px-4" },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "text-h5 vitrueGrey--text mt-6 px-5 mb-0"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("analytics.painAreasCard.title")) +
                            " "
                        )
                      ]
                    ),
                    _vm.painAnalytics
                      ? _c(
                          "v-row",
                          { attrs: { align: "center", justify: "center" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: _vm.containerColumns } },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { justify: "center" } },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "px-8" },
                                      [
                                        _c("pain-person", {
                                          attrs: {
                                            disabled: "",
                                            value: { gender: "", areas: {} },
                                            analyticsPainAreas: _vm.showInfo
                                              ? []
                                              : _vm.painAnalyticsWithColors
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.$vuetify.breakpoint.lgAndUp
                              ? _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: {
                                              cols: "6",
                                              "align-self": "end"
                                            }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-h4 vitrueRed--text mb-0",
                                                attrs: {
                                                  id: "assessmentsWithPain"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      Math.round(
                                                        _vm.painAnalytics
                                                          .percentageOfAssessmentsWithPain
                                                      )
                                                    ) +
                                                    "% "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: {
                                              cols: "6",
                                              "align-self": "end"
                                            }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-h5 vitrueRed--text mb-0",
                                                attrs: { id: "commonPainAreas" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.twoMostCommonPainAreas
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { cols: "6" }
                                          },
                                          [
                                            _c("p", { staticClass: "body-1" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "analytics.painAreasCard.reportPainSubtitle"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { cols: "6" }
                                          },
                                          [
                                            _c("p", { staticClass: "body-1" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "analytics.painAreasCard.commonAreasSubtitle"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              "max-width": "200",
                                              "content-class": "text-center",
                                              "open-on-click": "",
                                              color: "transparent"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-col",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "text-center",
                                                              attrs: {
                                                                cols: "6",
                                                                "align-self":
                                                                  "end"
                                                              }
                                                            },
                                                            "v-col",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-h4 vitrueOrange--text mb-0",
                                                              attrs: {
                                                                id:
                                                                  "averagePain"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.averageDisplayedPainLevel
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              4118779870
                                            )
                                          },
                                          [
                                            _c("vitrue-tooltip", {
                                              attrs: {
                                                message:
                                                  _vm.averagePainLevelTooltip
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              "max-width": "200",
                                              "content-class": "text-center",
                                              "open-on-click": "",
                                              color: "transparent"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-col",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "text-center",
                                                              attrs: {
                                                                cols: "6",
                                                                "align-self":
                                                                  "end"
                                                              }
                                                            },
                                                            "v-col",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-h4 vitrueOrange--text mb-0",
                                                              attrs: {
                                                                id:
                                                                  "assessmentsAbove5Pain"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .painAnalytics
                                                                      .numberOfAssessmentsAbove5Pain
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              494672279
                                            )
                                          },
                                          [
                                            _c("vitrue-tooltip", {
                                              attrs: {
                                                message: _vm.$t(
                                                  "tooltips.severePainLevel"
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { cols: "6" }
                                          },
                                          [
                                            _c("p", { staticClass: "body-1" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "analytics.painAreasCard.averagePainSubtitle"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { cols: "6" }
                                          },
                                          [
                                            _c("p", { staticClass: "body-1" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.severePainLevelText
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: _vm.containerColumns,
                                      "align-self": "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { cols: "12" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-h4 vitrueRed--text mb-0",
                                                attrs: {
                                                  id: "assessmentsWithPain"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      Math.round(
                                                        _vm.painAnalytics
                                                          .percentageOfAssessmentsWithPain
                                                      )
                                                    ) +
                                                    "% "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "body-1 mb-0" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "analytics.painAreasCard.reportPainSubtitle"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-center",
                                            attrs: { cols: "12" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-h5 vitrueRed--text mb-0",
                                                attrs: { id: "commonPainAreas" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.twoMostCommonPainAreas
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              { staticClass: "body-1 mb-0" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "analytics.painAreasCard.commonAreasSubtitle"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              "max-width": "200",
                                              "content-class": "text-center",
                                              "open-on-click": "",
                                              color: "transparent"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-col",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "text-center",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            "v-col",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-h4 vitrueOrange--text mb-0",
                                                              attrs: {
                                                                id:
                                                                  "averagePain"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.averageDisplayedPainLevel
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "body-1 mb-0"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "analytics.painAreasCard.averagePainSubtitle"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              625336456
                                            )
                                          },
                                          [
                                            _c("vitrue-tooltip", {
                                              attrs: {
                                                message:
                                                  _vm.averagePainLevelTooltip
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              "max-width": "200",
                                              "content-class": "text-center",
                                              "open-on-click": "",
                                              color: "transparent"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-col",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "text-center",
                                                              attrs: {
                                                                cols: "12"
                                                              }
                                                            },
                                                            "v-col",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-h4 vitrueOrange--text mb-0",
                                                              attrs: {
                                                                id:
                                                                  "assessmentsAbove5Pain"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .painAnalytics
                                                                      .numberOfAssessmentsAbove5Pain
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "body-1 mb-0"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.severePainLevelText
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1142751550
                                            )
                                          },
                                          [
                                            _c("vitrue-tooltip", {
                                              attrs: {
                                                message: _vm.$t(
                                                  "tooltips.severePainLevel"
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.painAnalytics
                      ? _c(
                          "v-row",
                          { attrs: { justify: "center" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  disabled: !_vm.anonymousResults,
                                  bottom: "",
                                  color: "transparent"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              _vm._b(
                                                { staticClass: "d-flex" },
                                                "div",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary",
                                                    disabled:
                                                      _vm.anonymousResults
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.detailedList = true
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "buttons.seeFullList"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2077766393
                                )
                              },
                              [
                                _c("vitrue-tooltip", {
                                  attrs: {
                                    message: _vm.$t("anonymousResults.tooltip")
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.painAnalytics
                      ? _c(
                          "v-row",
                          {
                            staticClass: "fill-height",
                            attrs: { justify: "center", align: "center" }
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                id: "loadingCircle",
                                size: 50,
                                width: 5,
                                indeterminate: "",
                                color: "disabled"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "back",
            fn: function() {
              return [
                _c(
                  "v-row",
                  {
                    staticClass: "px-8 fill-height",
                    attrs: { justify: "center", align: "center" }
                  },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c("p", {
                        staticClass: "text-body-1",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("analytics.painAreasCard.explanation")
                          )
                        }
                      })
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }