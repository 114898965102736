<template>
  <v-container fluid fill-height class="py-0">
    <v-row no-gutters class="my-4" justify="space-between" align="end">
      <v-col cols="12" md="6">
        <v-card class="pa-4 d-flex text-body-2" color="#EFF6FF">
          <v-icon class="mr-4">mdi-lightbulb-outline</v-icon>
          <div>{{ $t("insightsPage.informationText") }}</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="5">
        <v-row align="end">
          <v-col cols="11"
            ><v-text-field
              v-model="search"
              :label="
                team
                  ? $t('insightsPage.table.searchLabel.team')
                  : $t('insightsPage.table.searchLabel.individual')
              "
              append-icon="mdi-magnify"
              single-line
              style="min-width:15vw; padding-right:2vw"
              hide-details
            >
            </v-text-field
          ></v-col>
          <v-col cols="1">
            <v-tooltip left color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    :loading="exportingCsv"
                    @click="exportInsights()"
                  >
                    <v-icon color="primary">mdi-download</v-icon>
                  </v-btn>
                </div>
              </template>
              <vitrue-tooltip :message="$t('tooltips.downloadInsights')" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="fill-height" no-gutters v-if="team">
      <v-col cols="12"
        ><insights-table
          id="incompleteTeamActions"
          :actions="incompleteTeamActions"
          :headers="teamHeaders"
          :teams="team"
          :searchText="search"
      /></v-col>
      <v-col cols="12" class="text-h5 mt-12">{{
        $t("insightsPage.titles.complete")
      }}</v-col>
      <v-col cols="12"
        ><insights-table
          id="completeTeamActions"
          :actions="completeTeamActions"
          :headers="teamHeaders"
          :teams="team"
          grayOut
          :searchText="search"
      /></v-col>
    </v-row>
    <v-row class="fill-height" no-gutters v-else>
      <v-col cols="12"
        ><insights-table
          :actions="incompleteIndividualActions"
          :headers="individualHeaders"
          :teams="team"
          :searchText="search"
        />
      </v-col>
      <v-col cols="12" class="text-h5 mt-12">{{
        $t("insightsPage.titles.complete")
      }}</v-col>
      <v-col cols="12"
        ><insights-table
          :actions="completeIndividualActions"
          :headers="individualHeaders"
          :teams="team"
          :searchText="search"
          grayOut
      /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import InsightsTable from "./InsightsTable.vue";
import { getTeamInsights, getIndividualInsights } from "@/customApi";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import ExportInsights from "@/services/analytics/insights-export-service";

export default {
  name: "Insights",
  components: { InsightsTable, VitrueTooltip },
  props: {
    team: Boolean
  },
  mounted() {
    this.getTeamInsights();
  },
  watch: {
    async team(newVal) {
      this.search = "";
      if (!newVal) {
        await this.getIndividualInsights();
      } else {
        await this.getTeamInsights();
      }
    }
  },
  data() {
    return {
      exportingCsv: false,
      search: "",
      teamActions: [],
      individualActions: [],
      teamHeaders: [
        {
          text: this.$t("insightsPage.table.headers.impact"),
          value: "impact",
          width: "10%"
        },
        {
          text: this.$t("insightsPage.table.headers.issue"),
          value: "issue",
          width: "200px"
        },
        {
          text: this.$t("insightsPage.table.headers.action"),
          value: "action",
          width: "250px"
        },
        {
          text: this.$t("insightsPage.table.headers.benefits"),
          value: "benefits",
          width: "1%"
        },
        {
          text: this.$t("insightsPage.table.headers.done"),
          value: "done",
          align: "center",
          width: "5%"
        },
        {
          text: this.$t("insightsPage.table.headers.note"),
          value: "note",
          width: "20%"
        }
      ],
      individualHeaders: [
        {
          text: this.$t("insightsPage.table.headers.impact"),
          value: "impact",
          width: "10%",
          filterable: false
        },
        {
          text: this.$t("insightsPage.table.headers.email"),
          value: "person",
          width: "150px"
        },
        {
          text: this.$t("insightsPage.table.headers.action"),
          value: "action",
          width: "300px",
          filterable: false
        },
        {
          text: this.$t("insightsPage.table.headers.benefits"),
          value: "benefits",
          width: "1%",
          filterable: false
        },
        {
          text: this.$t("insightsPage.table.headers.done"),
          value: "done",
          align: "center",
          width: "5%",
          filterable: false
        },
        {
          text: this.$t("insightsPage.table.headers.note"),
          value: "note",
          width: "20%",
          filterable: false
        }
      ]
    };
  },
  computed: {
    completeTeamActions() {
      return this.teamActions.filter(x => x.complete);
    },
    incompleteTeamActions() {
      return this.teamActions.filter(x => !x.complete);
    },
    completeIndividualActions() {
      return this.individualActions.filter(
        x => !x.insights.map(a => a.complete).includes(false)
      );
    },
    incompleteIndividualActions() {
      let actions = this.individualActions.filter(x =>
        x.insights.map(a => a.complete).includes(false)
      );
      this.sortByComplete(actions);
      return actions;
    }
  },
  methods: {
    async getTeamInsights() {
      try {
        this.teamActions = await getTeamInsights();
      } catch (err) {}
    },
    async getIndividualInsights() {
      try {
        var individualArray = await getIndividualInsights();
        individualArray.forEach(x => {
          x.insights.forEach(a => {
            a.complete = !!a.complete;
          });
        });
        this.individualActions = individualArray.map(a => ({
          ...a,
          showAll: false
        }));
      } catch (err) {}
    },
    sortByComplete(insights) {
      insights.forEach(x => {
        x.insights.sort((a, b) => {
          return a.complete === b.complete ? 0 : a.complete ? 1 : -1;
        });
      });
    },
    async exportInsights() {
      try {
        this.exportingCsv = true;

        // team insights retrieved on mounted, individual may not be so get them here in case.
        if (this.individualActions.length === 0) {
          await this.getIndividualInsights();
        }

        let teamInsightsOrdered = [
          ...this.incompleteTeamActions,
          ...this.completeTeamActions
        ];

        let individualActionsOrdered = [
          ...this.incompleteIndividualActions,
          ...this.completeIndividualActions
        ];

        ExportInsights.createInsightsFile(
          teamInsightsOrdered,
          individualActionsOrdered
        );
      } catch (err) {
      } finally {
        this.exportingCsv = false;
      }
    }
  }
};
</script>
