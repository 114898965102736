var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      attrs: {
        justify: _vm.$vuetify.breakpoint.smAndDown ? "center" : "end",
        align: "center"
      }
    },
    [
      _c(
        "v-col",
        { staticClass: "py-0 px-1", attrs: { cols: "12", sm: "4" } },
        [
          _c("v-autocomplete", {
            attrs: {
              id: "tagSelector",
              items: _vm.tags,
              "item-text": "name",
              "item-value": "name",
              multiple: "",
              outlined: "",
              dense: "",
              "hide-details": "",
              "return-object": "",
              clearable: "",
              disabled: _vm.disabled
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  var attrs = ref.attrs
                  var on = ref.on
                  return [
                    _c(
                      "v-list-item",
                      _vm._g(
                        _vm._b(
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var active = ref.active
                                    return [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c("v-checkbox", {
                                            attrs: { "input-value": active }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.name
                                                    ? item.name
                                                    : _vm.$t(
                                                        "analytics.filter.everyone"
                                                      )
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          "v-list-item",
                          attrs,
                          false
                        ),
                        on
                      )
                    )
                  ]
                }
              },
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    index < 1
                      ? _c("span", { staticClass: "font-weight-regular" }, [
                          _vm._v(
                            _vm._s(
                              item.name
                                ? item.name
                                : _vm.$t("analytics.filter.everyone")
                            )
                          )
                        ])
                      : _vm._e(),
                    index === 1
                      ? _c("span", { staticClass: "grey--text caption ml-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$tc(
                                  "analytics.filter.tagNumber",
                                  _vm.filterTags.length - 1
                                )
                              )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "no-data",
                fn: function() {
                  return [
                    _c("span", { staticClass: "ml-3 caption no-overflow" }, [
                      _vm._v(_vm._s(_vm.$t("analytics.filter.noTags")))
                    ])
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.filterTags,
              callback: function($$v) {
                _vm.filterTags = $$v
              },
              expression: "filterTags"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "px-1",
          class: _vm.$vuetify.breakpoint.xs ? "pt-2" : "py-0",
          attrs: { cols: "12", sm: "4" }
        },
        [
          _c("v-autocomplete", {
            attrs: {
              id: "dateSelector",
              items: _vm.dates,
              outlined: "",
              dense: "",
              "hide-details": "",
              disabled: _vm.disabled
            },
            on: { change: _vm.getFilteredAnalytics },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", { staticClass: "no-overflow" }, [
                      _vm._v(_vm._s(_vm.getTimeIntervalText(item)))
                    ])
                  ]
                }
              },
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", { staticClass: "no-overflow" }, [
                      _vm._v(_vm._s(_vm.getTimeIntervalText(item)))
                    ])
                  ]
                }
              },
              {
                key: "prepend",
                fn: function() {
                  return [
                    !_vm.$vuetify.breakpoint.xs
                      ? _c("span", { staticClass: "text-body-1" }, [
                          _vm._v(_vm._s(_vm.$t("analytics.filter.over")))
                        ])
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.timeFrame,
              callback: function($$v) {
                _vm.timeFrame = $$v
              },
              expression: "timeFrame"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "px-1 py-0", attrs: { cols: "12", sm: "4" } },
        [
          _c("v-autocomplete", {
            attrs: {
              id: "locationSelector",
              items: _vm.locations,
              outlined: "",
              dense: "",
              "hide-details": "",
              disabled: _vm.disabled
            },
            on: { change: _vm.getFilteredAnalytics },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", { staticClass: "no-overflow" }, [
                      _vm._v(_vm._s(_vm.getLocationText(item)))
                    ])
                  ]
                }
              },
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", { staticClass: "no-overflow" }, [
                      _vm._v(_vm._s(_vm.getLocationText(item)))
                    ])
                  ]
                }
              },
              {
                key: "prepend",
                fn: function() {
                  return [
                    !_vm.$vuetify.breakpoint.xs
                      ? _c("span", { staticClass: "text-body-1" }, [
                          _vm._v(_vm._s(_vm.$t("analytics.filter.at")))
                        ])
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.filterLocation,
              callback: function($$v) {
                _vm.filterLocation = $$v
              },
              expression: "filterLocation"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }