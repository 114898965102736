<template>
  <v-row justify="center" no-gutters>
    <v-col
      id="titleContainer"
      cols="12"
      class="text-center text-h6"
      style="min-height:65px;"
      >{{ title }}</v-col
    >

    <v-tooltip bottom color="transparent">
      <template v-slot:activator="{ on, attrs }">
        <v-row justify="center" no-gutters v-bind="attrs" v-on="on">
          <v-col cols="12"
            ><DonutChart :values="donutValues" :colors="donutColours"
          /></v-col>
          <v-col id="textContainer" cols="12" class="text-center">
            <span v-html="descriptionText" />
          </v-col>
        </v-row>
      </template>
      <vitrue-tooltip :message="toolTip" />
    </v-tooltip>
  </v-row>
</template>

<script>
import DonutChart from "@/components/common/DonutChart";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  components: { DonutChart, VitrueTooltip },
  data() {
    return {
      donutColours: [
        this.$vuetify.theme.currentTheme.vitruePaleGreen,
        this.$vuetify.theme.currentTheme.primary,
        this.$vuetify.theme.currentTheme.vitrueYellow,
        this.$vuetify.theme.currentTheme.vitrueRed
      ]
    };
  },
  props: {
    title: String,
    values: Object,
    toolTip: String
  },
  computed: {
    peopleInVeryHigh() {
      return this.values.veryhigh;
    },
    donutValues() {
      return [
        this.values.low,
        this.values.average,
        this.values.high,
        this.values.veryhigh
      ];
    },
    descriptionText() {
      if (this.peopleInVeryHigh === 0) {
        return "";
      }
      let countText = this.$tc(
        "analytics.burnout.donut.descriptionPeople",
        this.peopleInVeryHigh,
        { n: this.peopleInVeryHigh }
      );
      let experienceText = this.$t(
        "analytics.burnout.donut.descriptionExperience"
      );
      return countText + " " + experienceText + " " + this.title.toLowerCase();
    }
  }
};
</script>
