var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        {
          staticClass: "d-flex flex-column py-0",
          attrs: { cols: "12", lg: "4" }
        },
        [
          _vm.$vuetify.breakpoint.md
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "5" } },
                    [
                      _c("overall-scores-card", {
                        attrs: { summaryAnalytics: _vm.summaryData }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "7" } },
                    [
                      _c("screen-height-analytics", {
                        attrs: { webcamAnalytics: _vm.webcamData }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.$vuetify.breakpoint.md
            ? _c("overall-scores-card", {
                staticClass: "mb-6",
                attrs: { summaryAnalytics: _vm.summaryData }
              })
            : _vm._e(),
          !_vm.$vuetify.breakpoint.md
            ? _c("screen-height-analytics", {
                attrs: { webcamAnalytics: _vm.webcamData }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "d-flex flex-column py-0",
          attrs: { cols: "12", lg: "4" }
        },
        [
          _c("pain-areas-card", {
            staticClass: "pa-0",
            class: _vm.$vuetify.breakpoint.mdAndDown ? "my-6" : "",
            attrs: { painAnalytics: _vm.painData }
          })
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "d-flex flex-column py-0",
          attrs: { cols: "12", lg: "4" }
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("category-card", {
                    staticClass: "mb-6 pa-0",
                    attrs: {
                      values: _vm.equipmentData
                        ? _vm.equipmentData.simsScores
                        : null
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.$vuetify.breakpoint.md
            ? _c(
                "v-row",
                { staticClass: "mb-6" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column py-0",
                      attrs: { cols: "6" }
                    },
                    [
                      _c("missing-equipment-list", {
                        staticClass: "flex-grow-1 pa-0",
                        attrs: { equipment: _vm.equipmentData }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column py-0",
                      attrs: { cols: "6" }
                    },
                    [
                      _vm.burnout
                        ? _c("category-card", {
                            staticClass: "flex-grow-1 pa-0",
                            attrs: {
                              burnout: "",
                              values: _vm.burnoutData
                                ? _vm.burnoutData.overallScores
                                : null,
                              detailedInformationValues: _vm.burnoutData
                            }
                          })
                        : _c("desk-wellness", {
                            staticClass: "flex-grow-1 pa-0",
                            attrs: { equipment: _vm.equipmentData }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.$vuetify.breakpoint.md
            ? _c("missing-equipment-list", {
                staticClass: "mb-6 pa-0",
                attrs: { equipment: _vm.equipmentData }
              })
            : _vm._e(),
          !_vm.$vuetify.breakpoint.md && _vm.burnout
            ? _c("category-card", {
                staticClass: "flex-grow-1 pa-0",
                attrs: {
                  burnout: "",
                  values: _vm.burnoutData
                    ? _vm.burnoutData.overallScores
                    : null,
                  detailedInformationValues: _vm.burnoutData
                }
              })
            : !_vm.$vuetify.breakpoint.md
            ? _c("desk-wellness", {
                staticClass: "flex-grow-1 pa-0",
                attrs: { equipment: _vm.equipmentData }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }