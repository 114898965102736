<template>
  <v-container fluid class="pa-0">
    <v-btn
      v-if="!hasNote"
      class="pa-0 ma-0"
      plain
      small
      color="blue"
      text
      @click="addNote()"
    >
      <span class="text-body-2 pa-0 ma-0">
        {{ $t("buttons.addNote") }}
      </span></v-btn
    >
    <v-textarea
      id="textArea"
      v-if="hasNote && textBoxHasFocus"
      hide-details
      v-model="currentText"
      outlined
      no-resize
      dense
      height="50px"
      class="text-caption"
      :style="highZoom ? 'width: 100%' : ''"
      @focus="textBoxHasFocus = true"
    >
    </v-textarea>
    <v-row v-if="hasNote && !textBoxHasFocus" align-content="center">
      <v-icon color="primary" class="mr-2" @click="textBoxHasFocus = true"
        >mdi-pencil</v-icon
      >
      <div
        id="textField"
        class="text-caption no-wrap non-selected-text-field"
        @click="textBoxHasFocus = true"
      >
        {{ currentText }}
      </div></v-row
    >

    <v-btn
      id="save"
      v-if="hasNote && textBoxHasFocus"
      plain
      rounded
      height="14px"
      width="28px"
      color="vitrueGrey"
      class="pa-0 ma-0 text-caption white--text"
      style="position: relative;"
      :style="highZoom ? 'left: 65%;' : 'left: 75%;'"
      @click="saveNote()"
    >
      {{ $t("buttons.save") }}</v-btn
    >
  </v-container>
</template>

<script>
export default {
  name: "InsightsNote",
  props: {
    value: String,
    bumpUp: Boolean
  },
  data() {
    return {
      textBoxHasFocus: false,
      hasNote: !!this.value,
      currentText: this.value
    };
  },
  methods: {
    addNote() {
      this.hasNote = true;
      this.textBoxHasFocus = true;
    },
    saveNote() {
      this.$emit("input", this.currentText);
      this.$emit("saveText", this.currentText);
      this.textBoxHasFocus = false;
      if (!this.currentText) {
        this.hasNote = false;
      }
    }
  },
  computed: {
    highZoom() {
      return window.devicePixelRatio > 1;
    }
  }
};
</script>

<style scoped>
::v-deep.v-text-field > .v-input__control > .v-input__slot {
  padding: 0px;
}

.bumpUpTextField {
  position: relative;
  bottom: 10px;
  width: 200px;
}

.no-wrap {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: break-word;
  max-width: 300px;
  text-overflow: ellipsis;
}

.non-selected-text-field {
  max-height: 35px;
  width: 280px;
  cursor: pointer;
}

/* scroll bar styles
/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}
</style>
