<template>
  <v-row
    :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'end'"
    align="center"
  >
    <v-col cols="12" sm="4" class="py-0 px-1">
      <v-autocomplete
        id="tagSelector"
        v-model="filterTags"
        :items="tags"
        item-text="name"
        item-value="name"
        multiple
        outlined
        dense
        hide-details
        return-object
        clearable
        :disabled="disabled"
      >
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name ? item.name : $t("analytics.filter.everyone") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item, index }">
          <span class="font-weight-regular" v-if="index < 1">{{
            item.name ? item.name : $t("analytics.filter.everyone")
          }}</span>
          <span v-if="index === 1" class="grey--text caption ml-1">
            {{ $tc("analytics.filter.tagNumber", filterTags.length - 1) }}</span
          >
        </template>
        <template v-slot:no-data>
          <span class="ml-3 caption no-overflow">{{
            $t("analytics.filter.noTags")
          }}</span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col
      cols="12"
      sm="4"
      class="px-1"
      :class="$vuetify.breakpoint.xs ? 'pt-2' : 'py-0'"
    >
      <v-autocomplete
        id="dateSelector"
        v-model="timeFrame"
        :items="dates"
        outlined
        dense
        hide-details
        :disabled="disabled"
        @change="getFilteredAnalytics"
      >
        <template v-slot:item="{ item }">
          <span class="no-overflow">{{ getTimeIntervalText(item) }}</span>
        </template>
        <template v-slot:selection="{ item }">
          <span class="no-overflow">{{ getTimeIntervalText(item) }}</span>
        </template>
        <template v-slot:prepend>
          <span v-if="!$vuetify.breakpoint.xs" class="text-body-1">{{
            $t("analytics.filter.over")
          }}</span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" sm="4" class="px-1 py-0">
      <v-autocomplete
        id="locationSelector"
        v-model="filterLocation"
        :items="locations"
        outlined
        dense
        hide-details
        :disabled="disabled"
        @change="getFilteredAnalytics"
      >
        <template v-slot:item="{ item }">
          <span class="no-overflow">{{ getLocationText(item) }}</span>
        </template>
        <template v-slot:selection="{ item }">
          <span class="no-overflow">{{ getLocationText(item) }}</span>
        </template>
        <template v-slot:prepend>
          <span v-if="!$vuetify.breakpoint.xs" class="text-body-1">{{
            $t("analytics.filter.at")
          }}</span>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { getTeamTags } from "@/customApi";

export default {
  name: "FilterAnalytics",
  data() {
    return {
      tags: ["Everyone"],
      dates: [
        "Past week",
        "Past month",
        "Past 3 months",
        "Past 6 months",
        "All time"
      ],
      locations: ["All Locations", "Home", "Office", "Other"],
      otherLocations: ["NotAtUsualWorkspace"],
      filterTags: ["Everyone"],
      timeFrame: "All time",
      disabled: false,
      filterLocation: "All Locations"
    };
  },
  watch: {
    filterTags(newVal, oldVal) {
      // If filtertags is updated based on new value, return to prevent
      // calling getFilteredAnalytics twice (as watch will be triggered again)
      if (oldVal.includes("Everyone") && newVal.length > 1) {
        const index = this.filterTags.indexOf("Everyone");
        this.filterTags.splice(index, 1);
        return;
      } else if (!oldVal.includes("Everyone") && newVal.includes("Everyone")) {
        this.filterTags = ["Everyone"];
        return;
      } else if (newVal.length === 0) {
        this.filterTags = ["Everyone"];
        return;
      }
      this.getFilteredAnalytics();
    }
  },
  mounted() {
    this.getTags();
  },
  methods: {
    getTimeIntervalText(item) {
      switch (item) {
        case "Past week":
          return this.$t("analytics.filter.pastWeek");
        case "Past month":
          return this.$t("analytics.filter.pastMonth");
        case "Past 3 months":
          return this.$t("analytics.filter.past3Months");
        case "Past 6 months":
          return this.$t("analytics.filter.past6Months");
        default:
          return this.$t("analytics.filter.allTime");
      }
    },
    getLocationText(item) {
      switch (item) {
        case "Home":
          return this.$t("analytics.filter.home");
        case "Office":
          return this.$t("analytics.filter.office");
        case "Other":
          return this.$t("analytics.filter.other");
        default:
          return this.$t("analytics.filter.all");
      }
    },
    async getTags() {
      try {
        let dto = {
          everythingTag: false
        };
        let tags = await getTeamTags(dto);
        tags.push("Everyone");
        this.tags = tags.filter(x => !x.expiredAt);
      } catch (error) {
        this.$logger.captureException(error);
        this.$emit("error");
      }
    },
    getLocations() {
      if (this.filterLocation === "All Locations") {
        return [];
      }

      if (this.filterLocation === "Other") {
        return this.otherLocations;
      }

      return [this.filterLocation];
    },
    async getFilteredAnalytics() {
      this.$emit("input", null);
      this.disabled = true;
      try {
        let tags = this.filterTags.includes("Everyone") ? [] : this.filterTags;
        let locations = this.getLocations();
        this.$emit("filter", {
          tags: tags,
          timeFrame: this.timeFrame,
          locations: locations
        });
      } catch (error) {
        this.$logger.captureException(error);
        this.$emit("error");
      } finally {
        this.disabled = false;
      }
    }
  }
};
</script>

<style scoped>
.no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>
