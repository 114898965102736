import XLSX from "xlsx";
import i18n from "@/plugins/i18n.js";
import InsightsTextServices from "@/services/analytics/insights-text-services.js";

export default {
  createInsightsFile(teamInsights, individualInsights) {
    var workBook = XLSX.utils.book_new();
    workBook.Props = {
      Title: i18n.t("insightsExportService.title"),
      Subject: i18n.t("insightsExportService.subject"),
      Author: "Vitrue Health",
      CreatedDate: new Date()
    };

    var teamActionsText = i18n.t("insightsExportService.teamActions");
    var individualActionsText = i18n.t(
      "insightsExportService.individualActions"
    );

    workBook.SheetNames.push(teamActionsText);
    workBook.SheetNames.push(individualActionsText);

    var teamData = this.buildTeamSheet(teamInsights);
    var teamWorkSheet = XLSX.utils.aoa_to_sheet(teamData);
    workBook.Sheets[teamActionsText] = teamWorkSheet;

    var individualData = this.buildIndividualSheet(individualInsights);
    var individualWorkSheet = XLSX.utils.aoa_to_sheet(individualData);
    workBook.Sheets[individualActionsText] = individualWorkSheet;

    XLSX.writeFile(workBook, "VitrueInsights.xlsx");
  },
  buildTeamSheet(teamInsights) {
    let dataArray = [];
    dataArray.push([
      i18n.t("insightsPage.table.headers.impact"),
      i18n.t("insightsPage.table.headers.issue"),
      i18n.t("insightsPage.table.headers.action"),
      i18n.t("insightsPage.table.headers.email"),
      i18n.t("insightsPage.table.headers.done")
    ]);

    teamInsights.forEach(action => {
      let firstRow = true;
      action.insights.forEach(insight => {
        if (firstRow) {
          dataArray.push([
            InsightsTextServices.methods.getImpactText(action.score),
            InsightsTextServices.methods.getIssueText(action.actionName),
            InsightsTextServices.methods.getActionText(action.actionName),
            insight.email.value,
            action.complete ? "Y" : "N"
          ]);
          firstRow = false;
          return;
        }
        dataArray.push(["", "", "", insight.email.value, ""]);
      });
      dataArray.push([]);
    });

    return dataArray;
  },
  buildIndividualSheet(individualInsights) {
    let dataArray = [];
    dataArray.push([
      i18n.t("insightsPage.table.headers.impact"),
      i18n.t("insightsPage.table.headers.email"),
      i18n.t("insightsPage.table.headers.action"),
      i18n.t("insightsPage.table.headers.done")
    ]);

    individualInsights.forEach(item => {
      let firstRow = true;
      item.insights.forEach(insight => {
        if (firstRow) {
          dataArray.push([
            InsightsTextServices.methods.getImpactText(
              InsightsTextServices.methods.getIndividualAverageScore(
                item.insights
              )
            ),
            insight.email.value,
            InsightsTextServices.methods.getActionText(insight.actionName),
            insight.complete ? "Y" : "N"
          ]);
          firstRow = false;
          return;
        }
        dataArray.push([
          "",
          "",
          InsightsTextServices.methods.getActionText(insight.actionName),
          insight.complete ? "Y" : "N"
        ]);
      });
      dataArray.push([]);
    });

    return dataArray;
  }
};
