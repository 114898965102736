var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "py-5 px-3" },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { icon: "", color: "black" },
          on: {
            click: function($event) {
              return _vm.$emit("input", false)
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-close")])],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-0",
                staticStyle: { height: "400px", "overflow-y": "auto" },
                attrs: {
                  headers: _vm.headers,
                  items: _vm.listOfItems,
                  dense: "",
                  "hide-default-footer": "",
                  "disable-filtering": "",
                  "disable-pagination": "",
                  "disable-sort": "",
                  "mobile-breakpoint": 0
                },
                scopedSlots: _vm._u([
                  {
                    key: "header.email",
                    fn: function(ref) {
                      var header = ref.header
                      return [
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v(_vm._s(header.text) + " ")
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              icon: "",
                              small: "",
                              width: "20px",
                              height: "20px"
                            },
                            on: {
                              click: function($event) {
                                return _vm.copyEmailsToClipboard()
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-content-copy")])],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("span", { staticClass: "data-hj-suppress" }, [
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.email",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("span", { staticClass: "data-hj-suppress" }, [
                          _vm._v(" " + _vm._s(item.email) + " ")
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            absolute: "",
            centered: "",
            color: "secondary",
            timeout: "1500",
            transition: "fade-transition",
            "content-class": "text-center"
          },
          model: {
            value: _vm.copySuccessful,
            callback: function($$v) {
              _vm.copySuccessful = $$v
            },
            expression: "copySuccessful"
          }
        },
        [_vm._v(_vm._s(_vm.$t("analytics.detailedList.copySuccess")))]
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            absolute: "",
            centered: "",
            color: "error",
            timeout: "1500",
            transition: "fade-transition",
            "content-class": "text-center"
          },
          model: {
            value: _vm.copyFailed,
            callback: function($$v) {
              _vm.copyFailed = $$v
            },
            expression: "copyFailed"
          }
        },
        [_vm._v(_vm._s(_vm.$t("analytics.detailedList.copyError")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }