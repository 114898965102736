var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { width: "100%" }, attrs: { fluid: "" } },
    [
      _vm.detailedList
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: _vm.detailedListWidth },
              model: {
                value: _vm.detailedList,
                callback: function($$v) {
                  _vm.detailedList = $$v
                },
                expression: "detailedList"
              }
            },
            [
              _c("detailed-list-dialog", {
                attrs: {
                  title: _vm.$t("analytics.missingEquipment.title"),
                  listOfItems: _vm.detailedListItems
                },
                model: {
                  value: _vm.detailedList,
                  callback: function($$v) {
                    _vm.detailedList = $$v
                  },
                  expression: "detailedList"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("flip-card", {
        attrs: {
          height: _vm.$vuetify.breakpoint.smAndDown ? "425px" : "400px",
          width: "100%"
        },
        scopedSlots: _vm._u([
          {
            key: "front",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "fill-height px-4 d-flex flex-column" },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "text-h5 vitrueGrey--text mt-6 px-6 mb-0"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("analytics.missingEquipment.subtitle")
                            ) +
                            " "
                        )
                      ]
                    ),
                    _vm.equipment && _vm.missingPrimaryEquipmentExists
                      ? _c(
                          "v-row",
                          {
                            attrs: {
                              "no-gutters": "",
                              justify: "center",
                              align: "center"
                            }
                          },
                          [
                            _c(
                              "v-list",
                              { attrs: { id: "missingItemsList" } },
                              _vm._l(_vm.topPrimaryMissingItems, function(
                                item,
                                i
                              ) {
                                return _c(
                                  "v-list-item",
                                  { key: i, staticClass: "text-start" },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-img", {
                                          attrs: {
                                            width: "40",
                                            height: "40",
                                            contain: "",
                                            src: _vm.getEmoji(item)
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "text-body-1 vitrueGrey--text",
                                            staticStyle: {
                                              "white-space": "normal"
                                            },
                                            attrs: {
                                              id: "missingItemsText" + i
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.getTitle(item)) + " - "
                                            ),
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.getValue(item)))
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.equipment && !_vm.missingPrimaryEquipmentExists
                      ? _c(
                          "v-row",
                          {
                            staticClass:
                              "text-center text-h6 vitrueGrey--text ma-auto",
                            staticStyle: { height: "90%" },
                            attrs: {
                              id: "noMissingEquipmentRow",
                              justify: "center",
                              align: "center"
                            }
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                width: "50",
                                height: "50",
                                contain: "",
                                src: require("@/assets/images/emojis/starStruck.png")
                              }
                            }),
                            _c("p", { staticClass: "my-1 ml-4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "analytics.missingEquipment.noMissingEquipment"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.equipment
                      ? _c(
                          "v-row",
                          {
                            staticClass: "mb-4",
                            attrs: { justify: "center", align: "end" }
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  disabled: !_vm.anonymousResults,
                                  bottom: "",
                                  color: "transparent"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              _vm._b(
                                                { staticClass: "d-flex" },
                                                "div",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary",
                                                    disabled:
                                                      _vm.anonymousResults
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.detailedList = true
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "buttons.seeFullList"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2077766393
                                )
                              },
                              [
                                _c("vitrue-tooltip", {
                                  attrs: {
                                    message: _vm.$t("anonymousResults.tooltip")
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.equipment
                      ? _c(
                          "v-row",
                          {
                            staticClass: "fill-height",
                            attrs: { justify: "center", align: "center" }
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                id: "loadingCircle",
                                size: 50,
                                width: 5,
                                indeterminate: "",
                                color: "disabled"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "back",
            fn: function() {
              return [
                _c(
                  "v-row",
                  {
                    staticClass: "px-8 fill-height",
                    attrs: { justify: "center", align: "center" }
                  },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c("p", { staticClass: "text-body-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("analytics.missingEquipment.explanation")
                            ) +
                            " "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }