<template>
  <v-card class="pa-5">
    <v-btn
      class="close-button"
      icon
      @click="$emit('input', false)"
      color="black"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-row justify="center">
      <v-col cols="12"
        ><p id="titleText" class="text-center text-h4 primary--text">
          {{ title }}
        </p></v-col
      >
      <v-col cols="12" md="8">
        <v-data-table
          :headers="headers"
          :items="listOfItems"
          :expanded.sync="expanded"
          item-key="name"
          show-expand
          class="elevation-0 removeRowHover"
          dense
          hide-default-header
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :mobile-breakpoint="0"
          style="height: 400px; overflow-y: auto"
        >
          <!-- Customise the headers -->
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr :colspan="headers.length">
                <th style="width: 1%"></th>
                <th
                  id="titleHeader"
                  class="text-body-2 font-weight-medium black--text"
                >
                  {{ title }}
                </th>
                <th
                  id="employeeHeader"
                  class="text-body-2 font-weight-medium black--text"
                >
                  {{ $t("analytics.detailedList.table.employees") }}
                </th>
              </tr>
            </thead>
          </template>

          <!-- Customise the expand icon -->
          <template
            v-slot:item.data-table-expand="{ item, expand, isExpanded }"
          >
            <v-icon
              color="primary"
              @click="expand(!isExpanded)"
              :disabled="item.number === 0"
              >{{
                isExpanded
                  ? "mdi-chevron-right mdi-rotate-90"
                  : "mdi-chevron-right"
              }}</v-icon
            >
          </template>

          <!-- Customise the for name of item -->
          <template v-slot:item.name="{ item }">
            <v-img
              class="mr-2"
              v-if="item.image"
              :src="require('@/assets/images/emojis/' + item.image)"
              :alt="item.image"
              height="20px"
              width="20px"
              style="float: left"
            />
            <span
              :id="item.name.replace(' ', '') + '-' + 'ItemText'"
              class="text-body-2 vitrueGrey--text"
              >{{ item.name }}</span
            >
          </template>

          <!-- Customise the for number of item -->
          <template v-slot:item.number="{ item }">
            <p
              :id="item.name.replace(' ', '') + '-' + 'ItemNumber'"
              class="my-0 text-body-2 vitrueGrey--text"
            >
              {{ item.number }}
            </p>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              :id="item.name.replace(' ', '') + '-' + 'ItemCopy'"
              :disabled="item.number === 0"
              icon
              @click="copyUsersToClipboard(item)"
              small
              width="20px"
              height="20px"
              v-if="expanded.includes(item)"
              ><v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>

          <!-- Customise the selected row drop down to be its own table of names and emails -->
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="pa-0 pb-3"
              style="background-color: white"
            >
              <v-simple-table
                dense
                :id="item.name.replace(' ', '') + '-' + 'InnerTable'"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="10%" class="text-caption black--text">
                        {{ valueHeader ? valueHeader : "" }}
                      </th>
                      <th
                        :width="$vuetify.breakpoint.mdAndUp ? '50%' : '0%'"
                        class="text-caption black--text"
                      >
                        {{ $t("analytics.detailedList.table.name") }}
                      </th>
                      <th width="40%" class="text-caption black--text">
                        {{ $t("analytics.detailedList.table.email") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="user in SortedAssociatedUsers(
                        item.associatedUsers
                      )"
                      :key="user.email"
                    >
                      <td
                        class="text-caption vitrueGrey--text"
                        style="font-size: 10px"
                      >
                        {{ user.value ? user.value : "" }}
                      </td>
                      <td
                        class="text-caption vitrueGrey--text inner-table-name-wrap data-hj-suppress"
                        style="font-size: 10px"
                      >
                        {{ user.name }}
                      </td>
                      <td
                        class="text-caption vitrueGrey--text inner-table-name-wrap data-hj-suppress"
                        style="font-size: 10px"
                      >
                        {{ user.email }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table></v-col
      >
      <v-snackbar
        v-model="copyUsersSuccessful"
        absolute
        centered
        color="secondary"
        timeout="1500"
        transition="fade-transition"
        content-class="text-center"
        >{{ $t("analytics.detailedList.copySuccess") }}</v-snackbar
      >
      <v-snackbar
        v-model="copyUsersFailed"
        absolute
        centered
        color="error"
        timeout="1500"
        transition="fade-transition"
        content-class="text-center"
        >{{ $t("analytics.detailedList.copyError") }}</v-snackbar
      >
      <v-col cols="5">
        <v-btn
          color="primary"
          x-large
          rounded
          outlined
          width="100%"
          @click="$emit('input', false)"
          >{{ $t("buttons.close") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Analytics from "@/components/dashboard/analytics/Analytics.vue";
export default {
  components: { Analytics },
  data() {
    return {
      headers: [
        { text: "", value: "data-table-expand", width: "1%" },
        {
          text: this.title,
          value: "name",
          width: "48%"
        },
        {
          text: this.$t("analytics.detailedList.table.employees"),
          value: "number",
          width: "48%"
        },
        {
          text: this.$t("analytics.detailedList.table.copy"),
          value: "actions",
          width: "1%"
        }
      ],
      expanded: [],
      copyUsersSuccessful: false,
      copyUsersFailed: false
    };
  },
  props: {
    value: Boolean,
    listOfItems: Array,
    title: String,
    valueHeader: String
  },
  methods: {
    copyUsersToClipboard(item) {
      var component = this;
      navigator.clipboard.writeText(this.generateClipboardText(item)).then(
        function () {
          component.copyUsersSuccessful = true;
        },
        function () {
          component.copyUsersFailed = true;
        }
      );
    },
    generateClipboardText(item) {
      var textToCopy = item.name + "\n";
      item.associatedUsers.forEach(u => {
        if (this.valueHeader) {
          textToCopy += this.valueHeader + ": " + u.value + " ";
        }
        textToCopy += this.$t("analytics.detailedList.copiedText", {
          0: u.name ? u.name : "",
          1: u.email
        });
      });
      return textToCopy;
    },
    SortedAssociatedUsers(list) {
      let compare = (a, b) => (a > b) - (a < b); // sort function
      let arrayToSort = [...list]; // need to copy array otherwise causes infinite loop
      // sort by highest value if exists, then by email
      arrayToSort.sort(function (a, b) {
        return compare(b.value, a.value) || compare(a.email, b.email);
      });
      return arrayToSort;
    }
  }
};
</script>

<style scoped>
.inner-table-name-wrap {
  overflow: hidden;
  white-space: nowrap;
  max-width: 75px;
  text-overflow: ellipsis;
}

/* Important to remove borders in child table */
.v-data-table td {
  border-bottom: none !important;
}
.v-data-table th {
  border-bottom: none !important;
}

/* Important to remove row hover table */
.removeRowHover tr:hover {
  background-color: #ffffff !important;
}

/* scroll bar styles
/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}
</style>
