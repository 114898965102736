var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", "no-gutters": "" } },
    [
      _c(
        "v-col",
        {
          staticClass: "text-center text-h6",
          staticStyle: { "min-height": "65px" },
          attrs: { id: "titleContainer", cols: "12" }
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "", color: "transparent" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-row",
                    _vm._g(
                      _vm._b(
                        { attrs: { justify: "center", "no-gutters": "" } },
                        "v-row",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("DonutChart", {
                            attrs: {
                              values: _vm.donutValues,
                              colors: _vm.donutColours
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { id: "textContainer", cols: "12" }
                        },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.descriptionText) }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("vitrue-tooltip", { attrs: { message: _vm.toolTip } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }