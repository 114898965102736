var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { width: "100%", height: "100%" }, attrs: { fluid: "" } },
    [
      _vm.detailedInformation
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: _vm.detailedDialogWidth },
              model: {
                value: _vm.detailedInformation,
                callback: function($$v) {
                  _vm.detailedInformation = $$v
                },
                expression: "detailedInformation"
              }
            },
            [
              _vm.burnout
                ? _c("DetailedBurnoutDialog", {
                    attrs: { scores: _vm.detailedInformationValues },
                    model: {
                      value: _vm.detailedInformation,
                      callback: function($$v) {
                        _vm.detailedInformation = $$v
                      },
                      expression: "detailedInformation"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("flip-card", {
        attrs: { height: _vm.height, width: "100%" },
        scopedSlots: _vm._u([
          {
            key: "front",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "fill-height d-flex flex-column px-4" },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-center text-h5 vitrueGrey--text mt-4 px-5 mb-0"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.burnout
                                ? _vm.$t(
                                    "analytics.categoryCards.burnout.title"
                                  )
                                : _vm.$t("analytics.categoryCards.setup.title")
                            ) +
                            " "
                        )
                      ]
                    ),
                    _vm.blurResults
                      ? _c(
                          "div",
                          {
                            staticClass: "overlay text-h6 text-center px-2 my-3"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "analytics.categoryCards.burnout.confidentiality"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.values
                      ? _c(
                          "v-row",
                          {
                            staticClass: "text-center mt-4",
                            class: _vm.blurResults ? "blur" : "",
                            attrs: { align: "center", "no-gutters": "" }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "my-0 text-h5",
                                    attrs: {
                                      align: "stretch",
                                      justify: "space-between",
                                      "no-gutters": ""
                                    }
                                  },
                                  _vm._l(_vm.displayedPercentages, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "v-col",
                                      { key: index },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: !_vm.burnout,
                                              top: _vm.burnout,
                                              "max-width": "300",
                                              "open-on-click": "",
                                              disabled: _vm.blurResults,
                                              color: "transparent"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-card",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "rounded-0 white--text",
                                                              staticStyle: {
                                                                width: "95%"
                                                              },
                                                              attrs: {
                                                                color:
                                                                  item.color,
                                                                disabled:
                                                                  _vm.blurResults,
                                                                flat: "",
                                                                id: item.title
                                                                  ? item.title.replace(
                                                                      " ",
                                                                      ""
                                                                    ) +
                                                                    "Percentage"
                                                                  : index
                                                              }
                                                            },
                                                            "v-card",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.value
                                                              ) + "%"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("vitrue-tooltip", {
                                              attrs: {
                                                message: _vm.$t(
                                                  item.tooltipText
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "text-body-1 mt-2",
                                            staticStyle: { width: "95%" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t(item.title)) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.values &&
                    _vm.detailedInformationValues &&
                    !_vm.blurResults
                      ? _c(
                          "v-row",
                          {
                            staticClass: "mb-4",
                            attrs: {
                              justify: "center",
                              align: "end",
                              "no-gutters": ""
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.detailedInformation = true
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("analytics.categoryCards.button")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.values
                      ? _c(
                          "v-row",
                          {
                            staticClass: "fill-height",
                            attrs: { justify: "center", align: "center" }
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                id: "loadingCircle",
                                size: 50,
                                width: 5,
                                indeterminate: "",
                                color: "disabled"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "back",
            fn: function() {
              return [
                _c(
                  "v-row",
                  {
                    staticClass: "fill-height px-8",
                    attrs: { justify: "center", align: "center" }
                  },
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c("p", { staticClass: "text-body-1" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t(_vm.cardInfo.description)) + " "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }