var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("flip-card", {
    attrs: { height: _vm.$vuetify.breakpoint.mdAndDown ? "600px" : "120%" },
    scopedSlots: _vm._u([
      {
        key: "front",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "fill-height d-flex flex-column px-8" },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-center text-h5 vitrueGrey--text mt-2 px-5 mb-0"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("analytics.screenHeightCard.title")) +
                        " "
                    )
                  ]
                ),
                _vm.webcamAnalytics
                  ? _c(
                      "v-row",
                      {
                        staticClass: "my-auto",
                        class: _vm.$vuetify.breakpoint.smAndDown
                          ? "text-center"
                          : "text-start",
                        attrs: { align: "center" }
                      },
                      [
                        _c("v-col", { attrs: { cols: "12", lg: "7" } }, [
                          _vm.getRangeCount([
                            "slightlyTooLow",
                            "slightlyTooHigh"
                          ]) > 0
                            ? _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass: "primary--text text-h4 my-0",
                                    attrs: { id: "rangeSlightlyAtRisk" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$tc(
                                            "analytics.screenHeightCard.people",
                                            _vm.getRangeCount([
                                              "slightlyTooLow",
                                              "slightlyTooHigh"
                                            ])
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-body-1 vitrueGrey--text"
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tc(
                                            "analytics.screenHeightCard.has",
                                            _vm.getRangeCount([
                                              "slightlyTooLow",
                                              "slightlyTooHigh"
                                            ])
                                          ) + " "
                                        )
                                      )
                                    ]),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "analytics.screenHeightCard.extraWeight",
                                            {
                                              "0": _vm.metricSystem
                                                ? "2kg"
                                                : "4.4 lbs"
                                            }
                                          )
                                        )
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.getRangeCount(["farTooLow", "farTooHigh"]) > 0
                            ? _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass: "primary--text text-h4 my-0",
                                    attrs: { id: "rangeVeryMuchAtRisk" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$tc(
                                            "analytics.screenHeightCard.people",
                                            _vm.getRangeCount([
                                              "farTooLow",
                                              "farTooHigh"
                                            ])
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-body-1 vitrueGrey--text"
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tc(
                                            "analytics.screenHeightCard.has",
                                            _vm.getRangeCount([
                                              "farTooLow",
                                              "farTooHigh"
                                            ])
                                          ) + " "
                                        )
                                      )
                                    ]),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "analytics.screenHeightCard.extraWeight",
                                            {
                                              "0": _vm.metricSystem
                                                ? "4kg"
                                                : "8.8 lbs"
                                            }
                                          )
                                        )
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm.getRangeCount(["farTooLow", "farTooHigh"]) +
                            _vm.getRangeCount([
                              "slightlyTooLow",
                              "slightlyTooHigh"
                            ]) ===
                          0
                            ? _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-body-1 vitrueGrey--text"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "analytics.screenHeightCard.wellSetUp"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "5" } },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "fill-height",
                                attrs: { align: "center" }
                              },
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/images/screenHeightAnalyticsPerson.svg"),
                                    contain: "",
                                    "max-height": _vm.$vuetify.breakpoint
                                      .mdAndUp
                                      ? 220
                                      : 180
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt-auto",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "text-center mt-4",
                                attrs: { align: "stretch", "no-gutters": "" }
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "my-0 text-h5",
                                        class: _vm.$vuetify.breakpoint.xsOnly
                                          ? "text-subtitle-1"
                                          : "text-h5",
                                        attrs: {
                                          align: "stretch",
                                          justify: "space-between",
                                          "no-gutters": ""
                                        }
                                      },
                                      _vm._l(_vm.percentageBoxes, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "v-col",
                                          { key: index },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  "max-width": "300",
                                                  top: "",
                                                  "content-class":
                                                    "text-center",
                                                  "open-on-click": "",
                                                  color: "transparent"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-card",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "rounded-0 white--text",
                                                                  staticStyle: {
                                                                    width: "95%"
                                                                  },
                                                                  attrs: {
                                                                    color:
                                                                      item.color,
                                                                    id:
                                                                      item.key,
                                                                    flat: ""
                                                                  }
                                                                },
                                                                "v-card",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.percentageValue
                                                                  ) +
                                                                  "% "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("vitrue-tooltip", {
                                                  attrs: {
                                                    message: item.tooltipText
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass: "text-body-1 mt-2",
                                                class: _vm.$vuetify.breakpoint
                                                  .xsOnly
                                                  ? "text-body-2"
                                                  : "text-body-1",
                                                staticStyle: { width: "95%" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.headerText) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.webcamAnalytics
                  ? _c(
                      "v-row",
                      {
                        staticClass: "fill-height",
                        attrs: { justify: "center", align: "center" }
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            id: "loadingCircle",
                            size: 50,
                            width: 5,
                            indeterminate: "",
                            color: "disabled"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "back",
        fn: function() {
          return [
            _c(
              "v-row",
              {
                staticClass: "px-8 fill-height",
                attrs: { justify: "center", align: "center" }
              },
              [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c("p", {
                    staticClass: "text-body-1",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("analytics.screenHeightCard.explanation")
                      )
                    }
                  })
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }