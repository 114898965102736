<template>
  <div class="fill-height py-4 px-6">
    <v-dialog
      v-model="insufficientAssessmentsDialog"
      v-if="insufficientAssessmentsDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '500'"
      persistent
    >
      <insufficient-assessments-dialog
        v-model="insufficientAssessmentsDialog"
        :tags="filter.tags.includes('Everyone') ? [] : filter.tags"
        :timeFrame="filter.timeFrame"
      />
    </v-dialog>
    <v-dialog
      v-model="feedbackDialog"
      v-if="feedbackDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '40%'"
      persistent
    >
      <feedback-dialog v-model="feedbackDialog" />
    </v-dialog>
    <feedback-button
      v-model="feedbackDialog"
      :style="$vuetify.breakpoint.smAndDown ? '' : 'top:50%;right: 0%'"
    />

    <v-row justify="space-between" class="mx-2 mb-4">
      <v-col
        cols="12"
        lg="5"
        :class="$vuetify.breakpoint.lgAndUp ? 'py-0' : ''"
      >
        <v-tabs
          v-model="tab"
          class="ml-4"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-2' : ''"
          :centered="$vuetify.breakpoint.smAndDown"
        >
          <v-tab
            href="#analytics"
            class="text-h6"
            :class="tab === 'analytics' ? 'font-weight-bold' : ''"
          >
            {{ $t("analytics.tabs.analytics") }}
          </v-tab>
          <v-tab
            href="#insights"
            class="text-h6"
            :class="tab === 'insights' ? 'font-weight-bold' : ''"
          >
            {{ $t("analytics.tabs.actions") }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" lg="7" align-self="end" class="py-0">
        <filter-analytics
          :key="_i18n.locale"
          v-if="tab == 'analytics'"
          @filter="filter = $event"
          @error="showError('genericError')"
        />
        <v-row
          no-gutters
          v-if="tab == 'insights'"
          style="width: 100%"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
          :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'end'"
        >
          <v-tooltip :disabled="!anonymousResults" bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <v-btn-toggle v-model="individualInsights" mandatory>
                <div>
                  <v-btn
                    width="150px"
                    class="white--text text-body-2 rounded-0"
                    :color="individualInsights ? 'disabled' : 'secondary'"
                  >
                    {{ $t("insightsPage.toggle.team") }}
                  </v-btn>
                </div>
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    width="150px"
                    class="white--text text-body-2 rounded-0"
                    :color="individualInsights ? 'secondary' : 'disabled'"
                    :disabled="anonymousResults"
                  >
                    {{ $t("insightsPage.toggle.individual") }}
                  </v-btn>
                </div>
              </v-btn-toggle>
            </template>
            <vitrue-tooltip :message="$t('anonymousResults.tooltip')" />
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab" style="width: 100%" touchless>
      <v-card
        color="transparent"
        class="elevation-0 pa-0 ma-2 fill-height"
        :disabled="insufficientAssessmentsDialog"
      >
        <v-tab-item
          :key="1"
          value="analytics"
          class="elevation-0 pa-0 ma-0 my-4 fill-height"
        >
          <overview
            :filter="filter"
            :class="insufficientAssessmentsDialog ? 'blur' : ''"
            @showError="showError($event)"
            @insufficientAssessments="insufficientAssessmentsDialog = true"
          />
        </v-tab-item>
      </v-card>
      <v-tab-item :key="2" value="insights">
        <insights :team="!individualInsights" />
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar id="errorMessage" v-model="error" timeout="-1" color="red"
      ><p class="text-center mb-0" v-html="errorMessage"></p>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="error = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import InsufficientAssessmentsDialog from "./InsufficientAssessmentsDialog.vue";
import FeedbackButton from "@/components/common/Feedback/FeedbackButton.vue";
import FeedbackDialog from "@/components/common/Feedback/FeedbackDialog.vue";
import FilterAnalytics from "./FilterAnalytics";
import Overview from "./Overview.vue";
import Insights from "./Insights.vue";
import LoadingSymbol from "@/components/common/loaders/LoadingSymbol.vue";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { mapMutations, mapGetters } from "vuex";
import { processAdminChecklist } from "@/services/onboarding/adminOnboarding";
import TeamService from "@/services/teamService.js";

export default {
  components: {
    InsufficientAssessmentsDialog,
    FeedbackButton,
    FeedbackDialog,
    FilterAnalytics,
    Overview,
    Insights,
    LoadingSymbol,
    VitrueTooltip
  },
  name: "Analytics",
  data() {
    return {
      feedbackDialog: false,
      tab: "analytics",
      individualInsights: false,
      insufficientAssessmentsDialog: false,
      error: false,
      filter: {
        tags: [],
        timeFrame: null,
        locations: []
      },
      errorMessage: this.$t("genericError")
    };
  },
  async mounted() {
    if (!this.teamMemberId) {
      let teamMberInfo = await TeamService.getTeamMemberInfo();
      this.setTeamMemberId(teamMberInfo.teamMemberId);
    }

    await processAdminChecklist("viewTeamData");
  },
  computed: {
    ...mapGetters(["teamMemberId", "anonymousResults"])
  },
  methods: {
    ...mapMutations(["setTeamMemberId"]),
    showError(type) {
      this.error = true;
      this.errorMessage = this.$t(type);
    }
  }
};
</script>

<style>
.blur {
  filter: blur(6px);
  -webkit-filter: blur(6px);
}
</style>
