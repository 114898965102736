var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      !_vm.hasNote
        ? _c(
            "v-btn",
            {
              staticClass: "pa-0 ma-0",
              attrs: { plain: "", small: "", color: "blue", text: "" },
              on: {
                click: function($event) {
                  return _vm.addNote()
                }
              }
            },
            [
              _c("span", { staticClass: "text-body-2 pa-0 ma-0" }, [
                _vm._v(" " + _vm._s(_vm.$t("buttons.addNote")) + " ")
              ])
            ]
          )
        : _vm._e(),
      _vm.hasNote && _vm.textBoxHasFocus
        ? _c("v-textarea", {
            staticClass: "text-caption",
            style: _vm.highZoom ? "width: 100%" : "",
            attrs: {
              id: "textArea",
              "hide-details": "",
              outlined: "",
              "no-resize": "",
              dense: "",
              height: "50px"
            },
            on: {
              focus: function($event) {
                _vm.textBoxHasFocus = true
              }
            },
            model: {
              value: _vm.currentText,
              callback: function($$v) {
                _vm.currentText = $$v
              },
              expression: "currentText"
            }
          })
        : _vm._e(),
      _vm.hasNote && !_vm.textBoxHasFocus
        ? _c(
            "v-row",
            { attrs: { "align-content": "center" } },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.textBoxHasFocus = true
                    }
                  }
                },
                [_vm._v("mdi-pencil")]
              ),
              _c(
                "div",
                {
                  staticClass: "text-caption no-wrap non-selected-text-field",
                  attrs: { id: "textField" },
                  on: {
                    click: function($event) {
                      _vm.textBoxHasFocus = true
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.currentText) + " ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.hasNote && _vm.textBoxHasFocus
        ? _c(
            "v-btn",
            {
              staticClass: "pa-0 ma-0 text-caption white--text",
              staticStyle: { position: "relative" },
              style: _vm.highZoom ? "left: 65%;" : "left: 75%;",
              attrs: {
                id: "save",
                plain: "",
                rounded: "",
                height: "14px",
                width: "28px",
                color: "vitrueGrey"
              },
              on: {
                click: function($event) {
                  return _vm.saveNote()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("buttons.save")))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }