var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _vm.showMissingPeople
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: _vm.peopleListWidth },
              model: {
                value: _vm.showMissingPeople,
                callback: function($$v) {
                  _vm.showMissingPeople = $$v
                },
                expression: "showMissingPeople"
              }
            },
            [
              _c("simple-list-dialog", {
                attrs: {
                  headers: _vm.missingPeopleHeaders,
                  listOfItems: _vm.activeMissingPeopleList
                },
                model: {
                  value: _vm.showMissingPeople,
                  callback: function($$v) {
                    _vm.showMissingPeople = $$v
                  },
                  expression: "showMissingPeople"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("v-data-table", {
        staticClass: "elevation-0 removeRowHover",
        attrs: {
          loading: _vm.dataTableLoading,
          headers: _vm.headers,
          items: _vm.actions,
          "items-per-page": _vm.numberOfActionsPerPage,
          "footer-props": { "items-per-page-options": [3, 5, 10, -1] },
          search: _vm.searchText,
          "custom-filter": _vm.customFilter,
          "disable-sort": "",
          "mobile-breakpoint": 0
        },
        scopedSlots: _vm._u(
          [
            _vm.teams
              ? {
                  key: "item.impact",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "text-body-1",
                          staticStyle: { width: "100%" },
                          attrs: {
                            id: "teamImpactChip",
                            color: _vm.getImpactColor(item.score),
                            dark: "",
                            small: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-center",
                              staticStyle: { width: "100%" }
                            },
                            [_vm._v(_vm._s(_vm.getImpactText(item.score)))]
                          )
                        ]
                      )
                    ]
                  }
                }
              : null,
            _vm.teams
              ? {
                  key: "item.issue",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "text-body-2",
                          attrs: { id: "teamIssueText" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.getIssueText(item.actionName)) + " "
                          ),
                          _c("br"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                disabled: !_vm.anonymousResults,
                                bottom: "",
                                color: "transparent"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              { staticClass: "d-flex" },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "pa-0 ma-0",
                                                attrs: {
                                                  plain: "",
                                                  small: "",
                                                  color: "blue",
                                                  text: "",
                                                  disabled: _vm.anonymousResults
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.showPeople(item)
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$tc(
                                                        "insightsPage.table.listOfPeopleText",
                                                        item.insights.length
                                                      )
                                                    )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("vitrue-tooltip", {
                                attrs: {
                                  message: _vm.$t("anonymousResults.tooltip")
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              : null,
            _vm.teams
              ? {
                  key: "item.action",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        { staticClass: "text-body-2" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "transparent" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticStyle: {
                                                  cursor: "default"
                                                },
                                                attrs: { id: "teamActionText" }
                                              },
                                              "div",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                attrs: { color: "primary" }
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-information-outline"
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getActionText(
                                                    item.actionName
                                                  )
                                                ) +
                                                " "
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("vitrue-tooltip", {
                                attrs: {
                                  message: _vm.getTooltipText(item.insights)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              : null,
            _vm.teams
              ? {
                  key: "item.benefits",
                  fn: function(ref) {
                    var item = ref.item
                    return _vm._l(
                      _vm.get2MostCommonBenefits(item.insights),
                      function(benefit, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "my-2",
                            attrs: { id: "teamBenefitChip" }
                          },
                          [
                            _c(
                              "v-chip",
                              {
                                staticClass: "text-body-1 my-1",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  color: _vm.getBenefitColor(benefit),
                                  dark: "",
                                  outlined: "",
                                  small: ""
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: { width: "100%" }
                                  },
                                  [_vm._v(_vm._s(_vm.getBenefitText(benefit)))]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      }
                    )
                  }
                }
              : null,
            _vm.teams
              ? {
                  key: "item.done",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("v-simple-checkbox", {
                        attrs: {
                          id: "teamCheckbox",
                          ripple: false,
                          color: "primary"
                        },
                        on: {
                          click: function($event) {
                            return _vm.saveActionDone(item)
                          }
                        },
                        model: {
                          value: item.complete,
                          callback: function($$v) {
                            _vm.$set(item, "complete", $$v)
                          },
                          expression: "item.complete"
                        }
                      })
                    ]
                  }
                }
              : null,
            _vm.teams
              ? {
                  key: "item.note",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("insights-note", {
                        key: item.actionName,
                        staticClass: "my-1",
                        attrs: { id: "teamNote" },
                        on: {
                          saveText: function($event) {
                            return _vm.saveNote(item)
                          }
                        },
                        model: {
                          value: item.teamNote,
                          callback: function($$v) {
                            _vm.$set(item, "teamNote", $$v)
                          },
                          expression: "item.teamNote"
                        }
                      })
                    ]
                  }
                }
              : null,
            !_vm.teams
              ? {
                  key: "body",
                  fn: function(ref) {
                    var items = ref.items
                    return [
                      _c(
                        "tbody",
                        _vm._l(items, function(item, i) {
                          return _c(
                            "tr",
                            { key: i, staticClass: "text-body-2" },
                            [
                              _c(
                                "td",
                                {
                                  staticClass: "py-3",
                                  staticStyle: { "vertical-align": "top" }
                                },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "text-body-1",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        id: "individualImpactChip",
                                        color: _vm.getImpactColor(
                                          _vm.getIndividualAverageScore(
                                            item.insights
                                          )
                                        ),
                                        dark: "",
                                        small: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-center",
                                          staticStyle: { width: "100%" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getImpactText(
                                                _vm.getIndividualAverageScore(
                                                  item.insights
                                                )
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "pb-4 pt-5 no-wrap",
                                  staticStyle: { "vertical-align": "top" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "data-hj-suppress",
                                      attrs: { id: "individualDetails" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.insights[0].name) + " "
                                      ),
                                      item.insights[0].name
                                        ? _c("br")
                                        : _vm._e(),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "mailto:" +
                                              item.insights[0].email.value
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.insights[0].email.value)
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "pb-4 pt-5",
                                  staticStyle: { "vertical-align": "top" }
                                },
                                [
                                  _vm._l(
                                    _vm.individualActionsToShow(item),
                                    function(insight, i) {
                                      return _c(
                                        "tr",
                                        {
                                          key: i,
                                          style: _vm.individualActionRowHeightStyle(
                                            insight
                                          )
                                        },
                                        [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    color: "transparent"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "div",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    class: insight.complete
                                                                      ? "text-decoration-line-through"
                                                                      : "",
                                                                    staticStyle: {
                                                                      cursor:
                                                                        "default"
                                                                    },
                                                                    attrs: {
                                                                      id:
                                                                        "individualActionText"
                                                                    }
                                                                  },
                                                                  "div",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "primary"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-information-outline"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getActionText(
                                                                        insight.actionName
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("vitrue-tooltip", {
                                                    attrs: {
                                                      message: _vm.getTooltipText(
                                                        [insight]
                                                      )
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  item.insights.length >
                                  _vm.defaultNumberOfIndividualActionsToShow
                                    ? _c("tr", [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "pa-0 ma-0",
                                                attrs: {
                                                  plain: "",
                                                  small: "",
                                                  color: "primary",
                                                  text: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    item.showAll = !item.showAll
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.showAll
                                                        ? "mdi-chevron-up"
                                                        : "mdi-chevron-down"
                                                    )
                                                  )
                                                ]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 pa-0 ma-0"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.showAll
                                                            ? _vm.$t(
                                                                "buttons.showLess"
                                                              )
                                                            : _vm.$t(
                                                                "buttons.showMore"
                                                              )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "py-3",
                                  staticStyle: { "vertical-align": "top" }
                                },
                                _vm._l(
                                  _vm.individualActionsToShow(item),
                                  function(insight, i) {
                                    return _c(
                                      "tr",
                                      {
                                        key: i,
                                        style: _vm.individualActionRowHeightStyle(
                                          insight
                                        )
                                      },
                                      [
                                        _c(
                                          "td",
                                          _vm._l(insight.benefits, function(
                                            benefit,
                                            i
                                          ) {
                                            return _c(
                                              "div",
                                              { key: i },
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    staticClass:
                                                      "text-body-1 mb-2",
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      id:
                                                        "individualBenefitChip",
                                                      color: _vm.getBenefitColor(
                                                        benefit
                                                      ),
                                                      dark: "",
                                                      outlined: "",
                                                      small: ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                        staticStyle: {
                                                          width: "100%"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getBenefitText(
                                                              benefit
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "py-4 justify-center",
                                  staticStyle: { "vertical-align": "top" }
                                },
                                _vm._l(
                                  _vm.individualActionsToShow(item),
                                  function(insight, i) {
                                    return _c(
                                      "tr",
                                      {
                                        key: i,
                                        staticClass:
                                          "d-flex justify-center align-start",
                                        style: _vm.individualActionRowHeightStyle(
                                          insight
                                        )
                                      },
                                      [
                                        _c(
                                          "td",
                                          [
                                            _c("v-simple-checkbox", {
                                              staticStyle: {
                                                "padding-top": "1px"
                                              },
                                              attrs: {
                                                ripple: false,
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.saveActionDone(
                                                    insight
                                                  )
                                                }
                                              },
                                              model: {
                                                value: insight.complete,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    insight,
                                                    "complete",
                                                    $$v
                                                  )
                                                },
                                                expression: "insight.complete"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "py-4 pt-4",
                                  staticStyle: { "vertical-align": "top" }
                                },
                                _vm._l(
                                  _vm.individualActionsToShow(item),
                                  function(insight, i) {
                                    return _c(
                                      "tr",
                                      {
                                        key: i,
                                        style: _vm.individualActionRowHeightStyle(
                                          insight
                                        )
                                      },
                                      [
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "vertical-align": "center"
                                            }
                                          },
                                          [
                                            _c("insights-note", {
                                              key: insight.id,
                                              attrs: { bumpUp: "" },
                                              on: {
                                                saveText: function($event) {
                                                  return _vm.saveNote(insight)
                                                }
                                              },
                                              model: {
                                                value: insight.individualNote,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    insight,
                                                    "individualNote",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "insight.individualNote"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  }
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }