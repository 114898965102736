<template>
  <v-container fluid style="width: 100%; height: 100%">
    <v-dialog
      persistent
      v-model="detailedInformation"
      :width="detailedDialogWidth"
      v-if="detailedInformation"
    >
      <DetailedBurnoutDialog
        v-if="burnout"
        v-model="detailedInformation"
        :scores="detailedInformationValues"
      />
    </v-dialog>
    <flip-card :height="height" width="100%">
      <template v-slot:front>
        <div class="fill-height d-flex flex-column px-4">
          <p class="text-center text-h5 vitrueGrey--text mt-4 px-5 mb-0">
            {{
              burnout
                ? $t("analytics.categoryCards.burnout.title")
                : $t("analytics.categoryCards.setup.title")
            }}
          </p>
          <div v-if="blurResults" class="overlay text-h6 text-center px-2 my-3">
            {{ $t("analytics.categoryCards.burnout.confidentiality") }}
          </div>
          <v-row
            class="text-center mt-4"
            v-if="values"
            align="center"
            no-gutters
            :class="blurResults ? 'blur' : ''"
          >
            <v-col cols="12">
              <v-row
                align="stretch"
                justify="space-between"
                class="my-0 text-h5"
                no-gutters
              >
                <v-col
                  v-for="(item, index) in displayedPercentages"
                  :key="index"
                >
                  <v-tooltip
                    :bottom="!burnout"
                    :top="burnout"
                    max-width="300"
                    open-on-click
                    :disabled="blurResults"
                    color="transparent"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-card
                        :color="item.color"
                        :disabled="blurResults"
                        flat
                        class="rounded-0 white--text"
                        style="width: 95%"
                        v-bind="attrs"
                        v-on="on"
                        :id="
                          item.title
                            ? item.title.replace(' ', '') + 'Percentage'
                            : index
                        "
                      >
                        <p>{{ item.value }}%</p>
                      </v-card>
                    </template>
                    <vitrue-tooltip :message="$t(item.tooltipText)" />
                  </v-tooltip>

                  <p class="text-body-1 mt-2" style="width: 95%">
                    {{ $t(item.title) }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="values && detailedInformationValues && !blurResults"
            justify="center"
            align="end"
            class="mb-4"
            no-gutters
            ><v-btn @click="detailedInformation = true" text color="primary">
              {{ $t("analytics.categoryCards.button") }}
            </v-btn></v-row
          >
          <v-row
            justify="center"
            align="center"
            v-if="!values"
            class="fill-height"
          >
            <v-progress-circular
              id="loadingCircle"
              :size="50"
              :width="5"
              indeterminate
              color="disabled"
            >
            </v-progress-circular>
          </v-row>
        </div>
      </template>
      <template v-slot:back>
        <v-row justify="center" align="center" class="fill-height px-8">
          <v-col cols="12">
            <p class="text-body-1">
              {{ $t(cardInfo.description) }}
            </p>
          </v-col>
        </v-row>
      </template>
    </flip-card>
  </v-container>
</template>

<script>
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import FlipCard from "@/components/common/FlipCard.vue";
import CardInformation from "@/assets/json/analytics/CardInformation.json";
import DeskAssessmentUtil from "@/services/deskassessment/desk-assessment-results-util";
import DetailedBurnoutDialog from "@/components/dashboard/analytics/analyticsCards/DetailedBurnoutDialog";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "CategoryCard",
  components: {
    FlipCard,
    DetailedBurnoutDialog,
    VitrueTooltip
  },
  props: {
    burnout: Boolean,
    values: Array,
    detailedInformationValues: Object
  },
  data() {
    const cardInfo = this.burnout
      ? CardInformation.burnout
      : CardInformation.workSetup;
    return {
      cardInfo: cardInfo,
      calculatedRatings: [],
      displayedPercentages: [
        {
          color: this.$vuetify.theme.currentTheme.vitrueRed
        },
        {
          color: this.$vuetify.theme.currentTheme.vitrueYellow
        },
        {
          color: this.$vuetify.theme.currentTheme.primary
        },
        {
          color: this.$vuetify.theme.currentTheme.vitruePaleGreen
        }
      ],
      blurResults: false,
      detailedInformation: false
    };
  },
  watch: {
    values: {
      handler(val) {
        if (val) {
          this.setRangePercentages();
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.values) {
      this.setRangePercentages();
    }
  },
  computed: {
    height() {
      if (this.$vuetify.breakpoint.md && this.burnout) {
        return "100%";
      }
      return this.$vuetify.breakpoint.smAndDown ? "250px" : "200px";
    },
    detailedDialogWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      }
      return "80%";
    }
  },
  methods: {
    setRangePercentages() {
      this.resetValues();
      this.blurResults = false;

      if (this.burnout) {
        if (this.values.length < 10) {
          this.blurResults = true;
          return;
        }
        this.displayedPercentages = this.displayedPercentages.reverse();
      }

      this.displayedPercentages.forEach((x, i) => {
        x.rating = this.$t(this.cardInfo.categoryRatings[i]);
        x.tooltipText = this.cardInfo.categoryTooltips[i];
        x.title = this.cardInfo.categoryTitles[i];
      });

      if (this.values.length == 0) {
        return;
      }
      this.values.forEach(score => {
        this.calculatedRatings.push(
          this.burnout
            ? BurnoutScores.getRiskFromScore(score, "overall")
            : DeskAssessmentUtil.getVisualDeskPlannerRatingFromScore(score)
        );
      });

      var totalPercentageValue = 0;

      // Calculate the percentage value of occurances for section/label
      this.displayedPercentages.forEach(x => {
        let calculatedRating = Math.round(
          (this.calculatedRatings.reduce(
            (acc, value) => (value === x.rating ? acc + 1 : acc),
            0
          ) /
            this.values.length) *
            100
        );
        totalPercentageValue += calculatedRating;
        x.value = calculatedRating;
      });

      // if total percentage is above 100 (possible due to rounding up each individiual score)
      // negate the extra amount from the last valued range
      if (totalPercentageValue > 100) {
        // map values to booleans in order to be able to easily get the last index of item where value is not 0
        const indexOfLastValuedItem = this.displayedPercentages
          .map(i => i.value !== 0)
          .lastIndexOf(true);

        let negateAmountFromLastRange = totalPercentageValue - 100;
        this.displayedPercentages[indexOfLastValuedItem].value -=
          negateAmountFromLastRange;
      }

      this.displayedPercentages = [...this.displayedPercentages];
    },
    resetValues() {
      this.calculatedRatings = [];
      this.displayedPercentages.forEach(i => {
        i.value = 0;
      });
      this.displayedPercentages = [...this.displayedPercentages];
    }
  }
};
</script>

<style scoped>
.blur {
  filter: blur(6px);
  -webkit-filter: blur(6px);
}
.overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
}
</style>
