<template>
  <flip-card :height="$vuetify.breakpoint.mdAndDown ? '600px' : '120%'">
    <template v-slot:front>
      <div class="fill-height d-flex flex-column px-8">
        <p class="text-center text-h5 vitrueGrey--text mt-2 px-5 mb-0">
          {{ $t("analytics.screenHeightCard.title") }}
        </p>
        <v-row
          v-if="webcamAnalytics"
          align="center"
          class="my-auto"
          :class="$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-start'"
        >
          <v-col cols="12" lg="7"
            ><div
              v-if="getRangeCount(['slightlyTooLow', 'slightlyTooHigh']) > 0"
            >
              <p id="rangeSlightlyAtRisk" class="primary--text text-h4 my-0">
                {{
                  $tc(
                    "analytics.screenHeightCard.people",
                    getRangeCount(["slightlyTooLow", "slightlyTooHigh"])
                  )
                }}
              </p>
              <p class="text-body-1 vitrueGrey--text">
                <span>{{
                  $tc(
                    "analytics.screenHeightCard.has",
                    getRangeCount(["slightlyTooLow", "slightlyTooHigh"])
                  ) + " "
                }}</span>
                <span
                  v-html="
                    $t('analytics.screenHeightCard.extraWeight', {
                      '0': metricSystem ? '2kg' : '4.4 lbs'
                    })
                  "
                />
              </p>
            </div>
            <div v-if="getRangeCount(['farTooLow', 'farTooHigh']) > 0">
              <p id="rangeVeryMuchAtRisk" class="primary--text text-h4 my-0">
                {{
                  $tc(
                    "analytics.screenHeightCard.people",
                    getRangeCount(["farTooLow", "farTooHigh"])
                  )
                }}
              </p>
              <p class="text-body-1 vitrueGrey--text">
                <span>{{
                  $tc(
                    "analytics.screenHeightCard.has",
                    getRangeCount(["farTooLow", "farTooHigh"])
                  ) + " "
                }}</span>
                <span
                  v-html="
                    $t('analytics.screenHeightCard.extraWeight', {
                      '0': metricSystem ? '4kg' : '8.8 lbs'
                    })
                  "
                />
              </p>
            </div>

            <div
              v-if="
                getRangeCount(['farTooLow', 'farTooHigh']) +
                  getRangeCount(['slightlyTooLow', 'slightlyTooHigh']) ===
                0
              "
            >
              <p class="text-body-1 vitrueGrey--text">
                {{ $t("analytics.screenHeightCard.wellSetUp") }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" lg="5">
            <v-row align="center" class="fill-height">
              <v-img
                :src="
                  require('@/assets/images/screenHeightAnalyticsPerson.svg')
                "
                contain
                :max-height="$vuetify.breakpoint.mdAndUp ? 220 : 180" /></v-row
          ></v-col>
          <div class="mt-auto" style="width: 100%">
            <v-row class="text-center mt-4" align="stretch" no-gutters>
              <v-col cols="12">
                <v-row
                  align="stretch"
                  justify="space-between"
                  class="my-0 text-h5"
                  :class="
                    $vuetify.breakpoint.xsOnly ? 'text-subtitle-1' : 'text-h5'
                  "
                  no-gutters
                >
                  <v-col v-for="(item, index) in percentageBoxes" :key="index">
                    <v-tooltip
                      max-width="300"
                      top
                      content-class="text-center"
                      open-on-click
                      color="transparent"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-card
                          :color="item.color"
                          :id="item.key"
                          flat
                          class="rounded-0 white--text"
                          style="width: 95%"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.percentageValue }}%
                        </v-card>
                      </template>
                      <vitrue-tooltip :message="item.tooltipText" />
                    </v-tooltip>
                    <p
                      class="text-body-1 mt-2"
                      style="width: 95%"
                      :class="
                        $vuetify.breakpoint.xsOnly
                          ? 'text-body-2'
                          : 'text-body-1'
                      "
                    >
                      {{ item.headerText }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <v-row
          justify="center"
          align="center"
          v-if="!webcamAnalytics"
          class="fill-height"
        >
          <v-progress-circular
            id="loadingCircle"
            :size="50"
            :width="5"
            indeterminate
            color="disabled"
          >
          </v-progress-circular>
        </v-row>
      </div>
    </template>
    <template v-slot:back>
      <v-row justify="center" align="center" class="px-8 fill-height">
        <v-col cols="12">
          <p
            class="text-body-1"
            v-html="$t('analytics.screenHeightCard.explanation')"
          />
        </v-col>
      </v-row>
    </template>
  </flip-card>
</template>

<script>
import { mapGetters } from "vuex";
import FlipCard from "@/components/common/FlipCard.vue";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "ScreenHeightAnalyticsCard",
  components: {
    FlipCard,
    VitrueTooltip
  },
  props: {
    webcamAnalytics: {
      type: Object
    }
  },
  watch: {
    webcamAnalytics: {
      handler() {
        if (!this.analyticsObjectEmpty) {
          this.setRangePercentages();
        }
      },
      deep: true
    }
  },
  data() {
    return {
      showInfo: false,
      percentageBoxes: [
        {
          key: "farTooHigh",
          color: this.$vuetify.theme.currentTheme.vitrueRed,
          headerText: this.$t("analytics.screenHeightCard.screen.tooLow"),
          percentageValue: 0,
          tooltipText: this.$t("tooltips.screen.highRisk")
        },
        {
          key: "slightlyTooHigh",
          color: this.$vuetify.theme.currentTheme.vitrueYellow,
          headerText: this.$t("analytics.screenHeightCard.screen.low"),
          percentageValue: 0,
          tooltipText: this.$t("tooltips.screen.lowRisk")
        },
        {
          key: "ideal",
          color: this.$vuetify.theme.currentTheme.vitruePaleGreen,
          headerText: this.$t("analytics.screenHeightCard.screen.ok"),
          percentageValue: 0,
          tooltipText: this.$t("tooltips.screen.ok")
        },
        {
          key: "slightlyTooLow",
          color: this.$vuetify.theme.currentTheme.vitrueYellow,
          headerText: this.$t("analytics.screenHeightCard.screen.high"),
          percentageValue: 0,
          tooltipText: this.$t("tooltips.screen.lowRisk")
        },
        {
          key: "farTooLow",
          color: this.$vuetify.theme.currentTheme.vitrueRed,
          headerText: this.$t("analytics.screenHeightCard.screen.tooHigh"),
          percentageValue: 0,
          tooltipText: this.$t("tooltips.screen.highRisk")
        }
      ]
    };
  },
  mounted() {
    if (this.webcamAnalytics) {
      this.setRangePercentages();
    }
  },
  computed: {
    ...mapGetters(["metricSystem"]),
    screenHeightCounts() {
      return this.webcamAnalytics.screenHeightCounts ?? this.webcamAnalytics;
    },
    totalCount() {
      if (!this.webcamAnalytics) {
        return 0;
      }
      return Object.values(this.screenHeightCounts).reduce((a, b) => a + b);
    }
  },
  methods: {
    setRangePercentages() {
      var totalPercentageValue = 0;

      this.percentageBoxes.forEach(element => {
        let percentageValue = this.getPercentageOfRange(element.key);
        element.percentageValue = percentageValue;
        totalPercentageValue += percentageValue;
      });

      // if total percentage is above 100 (possible due to rounding up each individiual score)
      // negate the extra amount from the last range
      if (totalPercentageValue > 100) {
        // map values to booleans in order to be able to easily get the last index of item where value is not 0
        const indexOfLastValuedItem = this.percentageBoxes
          .map(i => i.percentageValue !== 0)
          .lastIndexOf(true);

        let negateAmountFromLastRange = totalPercentageValue - 100;
        this.percentageBoxes[indexOfLastValuedItem].percentageValue -=
          negateAmountFromLastRange;
      }
    },
    getRangeCount(ranges) {
      var totalCount = 0;
      ranges.forEach(element => {
        totalCount += this.screenHeightCounts[element];
      });
      return totalCount;
    },
    getPercentageOfRange(range) {
      if (this.totalCount === 0) {
        return 0;
      }
      return Math.round(
        (this.screenHeightCounts[range] / this.totalCount) * 100
      );
    }
  }
};
</script>
