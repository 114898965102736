<template>
  <v-progress-circular
    :rotate="270"
    :size="150"
    :value="outer"
    :width="10"
    color="vitruePaleGreen"
  >
    <v-progress-circular
      :rotate="270"
      :size="120"
      :value="middle"
      :width="10"
      color="primary"
    >
      <v-progress-circular
        :rotate="270"
        :size="90"
        :value="inner"
        :width="10"
        color="vitrueOrange"
      >
      </v-progress-circular>
    </v-progress-circular>
  </v-progress-circular>
</template>

<script>
export default {
  name: "NestedCircularProgress",
  props: {
    outer: Number,
    middle: Number,
    inner: Number
  }
};
</script>
