var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.showBulkEmailReminder
        ? _c(
            "v-card",
            { staticClass: "pa-5" },
            [
              _vm.tags.length > 0 || _vm.timeFrame !== "All time"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "closeButton",
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("input", false)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                : _vm._e(),
              _c(
                "p",
                { staticClass: "text-center text-h3 primary--text pb-4 pt-6" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("analytics.insufficientAssessments.title")
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "text-body-1 text-center font-weight-bold mt-5"
                },
                [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("analytics.insufficientAssessments.subtitle")
                      )
                    }
                  }),
                  _c("p", { staticClass: "mb-0" }, [
                    _c("span", {
                      attrs: { id: "tagTimeFrameText" },
                      domProps: { innerHTML: _vm._s(_vm.tagTimeFrameText) }
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("analytics.insufficientAssessments.none")
                        ) +
                        ". "
                    )
                  ])
                ]
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "my-2", attrs: { cols: "10" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "dashboardButton",
                                color: "primary",
                                rounded: "",
                                outlined: "",
                                width: "100%"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push("/dashboard")
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.dashboard")))]
                          )
                        ],
                        1
                      ),
                      !_vm.disableEmails
                        ? _c(
                            "v-col",
                            { staticClass: "my-2", attrs: { cols: "10" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    bottom: "",
                                    disabled:
                                      !_vm.incompleteAssessmentsLoading &&
                                      _vm.incompleteAssessments.length !== 0,
                                    color: "transparent"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b({}, "div", attrs, false),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      id: "bulkReminderButton",
                                                      color: "secondary",
                                                      rounded: "",
                                                      outlined: "",
                                                      width: "100%",
                                                      loading:
                                                        _vm.incompleteAssessmentsLoading,
                                                      disabled:
                                                        _vm
                                                          .incompleteAssessments
                                                          .length === 0 ||
                                                        _vm.disableEmails
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.showBulkEmailReminder = true
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "buttons.bulkReminder"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3300943026
                                  )
                                },
                                [
                                  _c("vitrue-tooltip", {
                                    attrs: {
                                      message: _vm.$t(
                                        "tooltips.noPendingAssessments"
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showBulkEmailReminder
        ? _c("patient-bulk-reminder-dialog", {
            attrs: { incompleteAssessments: _vm.incompleteAssessments },
            on: {
              input: function($event) {
                _vm.showBulkEmailReminder = !_vm.showBulkEmailReminder
              },
              sent: function($event) {
                return _vm.$router.push("/dashboard")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }