var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-5" },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { icon: "", color: "black" },
          on: {
            click: function($event) {
              return _vm.$emit("input", false)
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-close")])],
        1
      ),
      _c(
        "v-row",
        { staticClass: "px-6", attrs: { justify: "space-around" } },
        [
          _c("v-col", { staticClass: "pa-0", attrs: { cols: "12" } }, [
            _c(
              "p",
              {
                staticClass: "text-center text-h4 primary--text",
                attrs: { id: "titleText" }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("analytics.burnout.detailsDialog.title")) +
                    " "
                )
              ]
            )
          ]),
          _c(
            "v-col",
            {
              staticClass: "mb-8 text-center",
              class: _vm.$vuetify.breakpoint.smAndDown ? "px-2" : " px-12",
              attrs: { cols: "12" }
            },
            [
              _vm._v(
                _vm._s(_vm.$t("analytics.burnout.detailsDialog.explanation")) +
                  " "
              )
            ]
          ),
          _c(
            "v-col",
            { staticClass: "my-2", attrs: { cols: "8", sm: "4", lg: "2" } },
            [
              _c("BurnoutDonut", {
                attrs: {
                  title: _vm.$t("burnoutSchema.cognitiveImpairment.title"),
                  values: _vm.getNumberOfPeopleInEachLevel("exhaustion"),
                  toolTip: _vm.$t(
                    "burnoutReportDetailsCards.cognitiveimpairment.description"
                  )
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "my-2", attrs: { cols: "8", sm: "4", lg: "2" } },
            [
              _c("BurnoutDonut", {
                attrs: {
                  title: _vm.$t("burnoutSchema.mentalDistance.title"),
                  values: _vm.getNumberOfPeopleInEachLevel("mentalDistance"),
                  toolTip: _vm.$t(
                    "burnoutReportDetailsCards.mentaldistance.description"
                  )
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "my-2", attrs: { cols: "8", sm: "4", lg: "2" } },
            [
              _c("BurnoutDonut", {
                attrs: {
                  title: _vm.$t("burnoutSchema.exhaustion.title"),
                  values: _vm.getNumberOfPeopleInEachLevel(
                    "cognitiveImpairment"
                  ),
                  toolTip: _vm.$t(
                    "burnoutReportDetailsCards.exhaustion.description"
                  )
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "my-2", attrs: { cols: "8", sm: "4", lg: "2" } },
            [
              _c("BurnoutDonut", {
                attrs: {
                  title: _vm.$t("burnoutSchema.emotionalImpairment.title"),
                  values: _vm.getNumberOfPeopleInEachLevel(
                    "emotionalImpairment"
                  ),
                  toolTip: _vm.$t(
                    "burnoutReportDetailsCards.emotionalimpairment.description"
                  )
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "my-2", attrs: { cols: "8", sm: "4", lg: "2" } },
            [
              _c("BurnoutDonut", {
                attrs: {
                  title: _vm.$t("burnoutSchema.secondarySymptoms.title"),
                  values: _vm.getNumberOfPeopleInEachLevel("secondarySymptoms"),
                  toolTip: _vm.$t(
                    "burnoutReportDetailsCards.secondarysymptoms.description"
                  )
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center", attrs: { justify: "center" } },
                [
                  _c("v-col", { attrs: { cols: "6", md: "3", lg: "2" } }, [
                    _c("span", {
                      staticClass: "dot mr-2",
                      style: _vm.getLevelColor("low")
                    }),
                    _vm._v(_vm._s(_vm.$t("burnoutReportSummary.severity.low")))
                  ]),
                  _c("v-col", { attrs: { cols: "6", md: "3", lg: "2" } }, [
                    _c("span", {
                      staticClass: "dot mr-2",
                      style: _vm.getLevelColor("average")
                    }),
                    _vm._v(
                      _vm._s(_vm.$t("burnoutReportSummary.severity.average"))
                    )
                  ]),
                  _c("v-col", { attrs: { cols: "6", md: "3", lg: "2" } }, [
                    _c("span", {
                      staticClass: "dot mr-2",
                      style: _vm.getLevelColor("high")
                    }),
                    _vm._v(_vm._s(_vm.$t("burnoutReportSummary.severity.high")))
                  ]),
                  _c("v-col", { attrs: { cols: "6", md: "3", lg: "2" } }, [
                    _c("span", {
                      staticClass: "dot mr-2",
                      style: _vm.getLevelColor("veryhigh")
                    }),
                    _vm._v(
                      _vm._s(_vm.$t("burnoutReportSummary.severity.veryHigh"))
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }