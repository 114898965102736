<template>
  <v-container fluid style="width: 100%; height: 100%">
    <v-dialog
      persistent
      v-model="detailedList"
      :width="detailedListWidth"
      v-if="detailedList"
    >
      <detailed-list-dialog
        :title="$t('analytics.painAreasCard.popup.title')"
        :valueHeader="$t('analytics.painAreasCard.popup.valueHeader')"
        v-model="detailedList"
        :listOfItems="detailedListItems"
      />
    </v-dialog>

    <flip-card :height="cardHeight" width="100%">
      <template v-slot:front>
        <div class="fill-height d-flex flex-column px-4">
          <p class="text-h5 vitrueGrey--text mt-6 px-5 mb-0">
            {{ $t("analytics.painAreasCard.title") }}
          </p>
          <v-row v-if="painAnalytics" align="center" justify="center">
            <v-col :cols="containerColumns">
              <v-row justify="center">
                <v-col class="px-8">
                  <pain-person
                    disabled
                    :value="{ gender: '', areas: {} }"
                    :analyticsPainAreas="
                      showInfo ? [] : painAnalyticsWithColors
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.lgAndUp">
              <v-row no-gutters>
                <v-col class="text-center" cols="6" align-self="end">
                  <p
                    id="assessmentsWithPain"
                    class="text-h4 vitrueRed--text mb-0"
                  >
                    {{
                      Math.round(painAnalytics.percentageOfAssessmentsWithPain)
                    }}%
                  </p>
                </v-col>
                <v-col class="text-center" cols="6" align-self="end">
                  <p id="commonPainAreas" class="text-h5 vitrueRed--text mb-0">
                    {{ twoMostCommonPainAreas }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters
                ><v-col class="text-center" cols="6"
                  ><p class="body-1">
                    {{ $t("analytics.painAreasCard.reportPainSubtitle") }}
                  </p>
                </v-col>
                <v-col class="text-center" cols="6"
                  ><p class="body-1">
                    {{ $t("analytics.painAreasCard.commonAreasSubtitle") }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-tooltip
                  top
                  max-width="200"
                  content-class="text-center"
                  open-on-click
                  color="transparent"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col
                      class="text-center"
                      cols="6"
                      align-self="end"
                      v-bind="attrs"
                      v-on="on"
                      ><p
                        id="averagePain"
                        class="text-h4 vitrueOrange--text mb-0"
                      >
                        {{ averageDisplayedPainLevel }}
                      </p>
                    </v-col>
                  </template>
                  <vitrue-tooltip :message="averagePainLevelTooltip" />
                </v-tooltip>
                <v-tooltip
                  top
                  max-width="200"
                  content-class="text-center"
                  open-on-click
                  color="transparent"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col
                      class="text-center"
                      cols="6"
                      align-self="end"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <p
                        id="assessmentsAbove5Pain"
                        class="text-h4 vitrueOrange--text mb-0"
                      >
                        {{ painAnalytics.numberOfAssessmentsAbove5Pain }}
                      </p>
                    </v-col>
                  </template>
                  <vitrue-tooltip :message="$t('tooltips.severePainLevel')" />
                </v-tooltip>
              </v-row>
              <v-row no-gutters>
                <v-col class="text-center" cols="6"
                  ><p class="body-1">
                    {{ $t("analytics.painAreasCard.averagePainSubtitle") }}
                  </p>
                </v-col>
                <v-col class="text-center" cols="6"
                  ><p class="body-1">
                    {{ severePainLevelText }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else :cols="containerColumns" align-self="center">
              <v-row>
                <v-col class="text-center" cols="12">
                  <p
                    id="assessmentsWithPain"
                    class="text-h4 vitrueRed--text mb-0"
                  >
                    {{
                      Math.round(painAnalytics.percentageOfAssessmentsWithPain)
                    }}%
                  </p>
                  <p class="body-1 mb-0">
                    {{ $t("analytics.painAreasCard.reportPainSubtitle") }}
                  </p>
                </v-col>
                <v-col class="text-center" cols="12">
                  <p id="commonPainAreas" class="text-h5 vitrueRed--text mb-0">
                    {{ twoMostCommonPainAreas }}
                  </p>
                  <p class="body-1 mb-0">
                    {{ $t("analytics.painAreasCard.commonAreasSubtitle") }}
                  </p>
                </v-col>
                <v-tooltip
                  top
                  max-width="200"
                  content-class="text-center"
                  open-on-click
                  color="transparent"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col
                      class="text-center"
                      cols="12"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <p
                        id="averagePain"
                        class="text-h4 vitrueOrange--text mb-0"
                      >
                        {{ averageDisplayedPainLevel }}
                      </p>
                      <p class="body-1 mb-0">
                        {{ $t("analytics.painAreasCard.averagePainSubtitle") }}
                      </p>
                    </v-col>
                  </template>
                  <vitrue-tooltip :message="averagePainLevelTooltip" />
                </v-tooltip>
                <v-tooltip
                  top
                  max-width="200"
                  content-class="text-center"
                  open-on-click
                  color="transparent"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col
                      class="text-center"
                      cols="12"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <p
                        id="assessmentsAbove5Pain"
                        class="text-h4 vitrueOrange--text mb-0"
                      >
                        {{ painAnalytics.numberOfAssessmentsAbove5Pain }}
                      </p>
                      <p class="body-1 mb-0">
                        {{ severePainLevelText }}
                      </p>
                    </v-col>
                  </template>
                  <vitrue-tooltip :message="$t('tooltips.severePainLevel')" />
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="painAnalytics" justify="center">
            <v-tooltip :disabled="!anonymousResults" bottom color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs" class="d-flex">
                  <v-btn
                    @click="detailedList = true"
                    text
                    color="primary"
                    :disabled="anonymousResults"
                  >
                    {{ $t("buttons.seeFullList") }}
                  </v-btn>
                </div>
              </template>
              <vitrue-tooltip :message="$t('anonymousResults.tooltip')" />
            </v-tooltip>
          </v-row>
          <v-row
            justify="center"
            align="center"
            v-if="!painAnalytics"
            class="fill-height"
          >
            <v-progress-circular
              id="loadingCircle"
              :size="50"
              :width="5"
              indeterminate
              color="disabled"
            >
            </v-progress-circular>
          </v-row>
        </div>
      </template>
      <template v-slot:back>
        <v-row justify="center" align="center" class="px-8 fill-height">
          <v-col cols="12">
            <p
              class="text-body-1"
              v-html="$t('analytics.painAreasCard.explanation')"
            />
          </v-col>
        </v-row>
      </template>
    </flip-card>
  </v-container>
</template>

<script>
import PainPerson from "@/components/painareas/PainPerson";
import PainRegions from "@/components/painareas/PainAreasMale.json";
import FlipCard from "@/components/common/FlipCard.vue";
import PainLevelTitles from "@/assets/json/DeskAssessment/PainLevelTitles.json";
import DetailedListDialog from "./DetailedListDialog.vue";
import { mapGetters } from "vuex";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "PainAreasCard",
  components: {
    PainPerson,
    FlipCard,
    DetailedListDialog,
    VitrueTooltip
  },
  props: {
    painAnalytics: Object
  },
  data() {
    return {
      showInfo: false,
      detailedList: false
    };
  },
  computed: {
    ...mapGetters(["anonymousResults"]),
    averageDisplayedPainLevel() {
      return Math.round(this.painAnalytics.averagePainLevel * 10) / 10;
    },
    averagePainLevelTooltip() {
      return this.$t("tooltips.averagePainLevel", {
        0: this.averageDisplayedPainLevel,
        1: this.$t(
          PainLevelTitles[Math.floor(this.averageDisplayedPainLevel)]
        ).toLowerCase()
      });
    },
    severePainLevelText() {
      return this.$tc(
        "analytics.painAreasCard.severePainSubtitle",
        this.painAnalytics.numberOfAssessmentsAbove5Pain,
        { n: this.painAnalytics.numberOfAssessmentsAbove5Pain }
      );
    },
    painAnalyticsWithColors() {
      let resultsWithPainAreas = this.painAnalytics.painAreaCounts;
      resultsWithPainAreas.forEach(area => {
        area.color = this.getPainAreaColor(area);
      });
      return resultsWithPainAreas;
    },
    containerColumns() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm
        ? 6
        : 12;
    },
    twoMostCommonPainAreas() {
      let firstMostCommon = this.painAnalyticsWithColors[0];
      let secondMostCommon = this.painAnalyticsWithColors[1];
      let combined;
      if (!firstMostCommon && !secondMostCommon) {
        return this.$t("analytics.painAreasCard.noPain");
      } else if (firstMostCommon && !secondMostCommon) {
        combined = this.$t(PainRegions[firstMostCommon.id].shortenedName);
      } else {
        combined =
          this.$t(PainRegions[firstMostCommon.id].shortenedName) +
          ` ${this.$t("analytics.painAreasCard.and")} ` +
          this.$t(PainRegions[secondMostCommon.id].shortenedName);
      }
      return combined.slice(0, 1) + combined.substring(1).toLowerCase();
    },
    cardHeight() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm) {
          return "625px";
        }
        return "980px";
      }
      return "100%";
    },
    detailedListWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return "80%";
      }

      return window.devicePixelRatio > 1 ? "70%" : "45%";
    },
    detailedListItems() {
      return this.painAnalytics.userPainAreas.map(item => {
        return {
          name: this.$t(PainRegions[item.keyId].name),
          image: undefined,
          number: item.associatedUsers.length,
          associatedUsers: item.associatedUsers.map(user => {
            return { name: user.name, email: user.email, value: user.value };
          })
        };
      });
    }
  },
  methods: {
    getPainAreaColor(area) {
      if (area.percentage > 20) {
        return this.$vuetify.theme.currentTheme.vitrueRed;
      } else if (area.percentage > 10) {
        return this.$vuetify.theme.currentTheme.vitrueOrange;
      }
      return this.$vuetify.theme.currentTheme.vitrueYellow;
    }
  }
};
</script>
