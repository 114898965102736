<template>
  <v-card class="py-5 px-3">
    <v-btn
      class="close-button"
      icon
      @click="$emit('input', false)"
      color="black"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="listOfItems"
          dense
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :mobile-breakpoint="0"
          style="height: 400px; overflow-y: auto"
        >
          <template v-slot:header.email="{ header }">
            <span class="mr-2">{{ header.text }} </span>
            <v-btn
              icon
              @click="copyEmailsToClipboard()"
              small
              width="20px"
              height="20px"
              ><v-icon>mdi-content-copy</v-icon></v-btn
            >
          </template>
          <template v-slot:item.name="{ item }">
            <span class="data-hj-suppress">
              {{ item.name }}
            </span>
          </template>
          <template v-slot:item.email="{ item }">
            <span class="data-hj-suppress">
              {{ item.email }}
            </span>
          </template>
        </v-data-table></v-col
      ></v-row
    >
    <v-snackbar
      v-model="copySuccessful"
      absolute
      centered
      color="secondary"
      timeout="1500"
      transition="fade-transition"
      content-class="text-center"
      >{{ $t("analytics.detailedList.copySuccess") }}</v-snackbar
    >
    <v-snackbar
      v-model="copyFailed"
      absolute
      centered
      color="error"
      timeout="1500"
      transition="fade-transition"
      content-class="text-center"
      >{{ $t("analytics.detailedList.copyError") }}</v-snackbar
    >
  </v-card>
</template>

<script>
export default {
  name: "SimpleListDialog",
  props: {
    headers: Array,
    listOfItems: Array,
    copySuccessful: false,
    copyFailed: false
  },
  methods: {
    copyEmailsToClipboard() {
      var textToCopy = "Emails \n";
      this.listOfItems.forEach(i => {
        textToCopy += i.email + "\n";
      });

      var component = this;
      navigator.clipboard.writeText(textToCopy).then(
        function () {
          component.copySuccessful = true;
        },
        function () {
          component.copyFailed = true;
        }
      );
    }
  }
};
</script>

<style scoped>
/* scroll bar styles
/* Works on Firefox */
* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}
</style>
