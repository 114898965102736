<template>
  <v-container fluid style="width: 100%">
    <v-dialog
      persistent
      v-model="detailedList"
      :width="detailedListWidth"
      v-if="detailedList"
    >
      <detailed-list-dialog
        :title="$t('analytics.missingEquipment.title')"
        v-model="detailedList"
        :listOfItems="detailedListItems"
      />
    </v-dialog>
    <flip-card
      :height="$vuetify.breakpoint.smAndDown ? '425px' : '400px'"
      width="100%"
    >
      <template v-slot:front>
        <div class="fill-height px-4 d-flex flex-column">
          <p class="text-h5 vitrueGrey--text mt-6 px-6 mb-0">
            {{ $t("analytics.missingEquipment.subtitle") }}
          </p>
          <v-row
            v-if="equipment && missingPrimaryEquipmentExists"
            no-gutters
            justify="center"
            align="center"
            ><v-list id="missingItemsList">
              <v-list-item
                class="text-start"
                v-for="(item, i) in topPrimaryMissingItems"
                :key="i"
              >
                <v-list-item-icon>
                  <v-img width="40" height="40" contain :src="getEmoji(item)" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :id="'missingItemsText' + i"
                    class="text-body-1 vitrueGrey--text"
                    style="white-space: normal"
                    >{{ getTitle(item) }} -
                    <span>{{ getValue(item) }}</span></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list></v-row
          >
          <v-row
            v-if="equipment && !missingPrimaryEquipmentExists"
            id="noMissingEquipmentRow"
            class="text-center text-h6 vitrueGrey--text ma-auto"
            justify="center"
            align="center"
            style="height: 90%"
          >
            <v-img
              width="50"
              height="50"
              contain
              :src="require('@/assets/images/emojis/starStruck.png')"
            />
            <p class="my-1 ml-4">
              {{ $t("analytics.missingEquipment.noMissingEquipment") }}
            </p></v-row
          >
          <v-row v-if="equipment" justify="center" align="end" class="mb-4">
            <v-tooltip :disabled="!anonymousResults" bottom color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs" class="d-flex">
                  <v-btn
                    @click="detailedList = true"
                    text
                    color="primary"
                    :disabled="anonymousResults"
                  >
                    {{ $t("buttons.seeFullList") }}
                  </v-btn>
                </div>
              </template>
              <vitrue-tooltip :message="$t('anonymousResults.tooltip')" />
            </v-tooltip>
          </v-row>
          <v-row
            justify="center"
            align="center"
            v-if="!equipment"
            class="fill-height"
          >
            <v-progress-circular
              id="loadingCircle"
              :size="50"
              :width="5"
              indeterminate
              color="disabled"
            >
            </v-progress-circular>
          </v-row>
        </div>
      </template>
      <template v-slot:back>
        <v-row justify="center" align="center" class="px-8 fill-height">
          <v-col cols="12">
            <p class="text-body-1">
              {{ $t("analytics.missingEquipment.explanation") }}
            </p>
          </v-col>
        </v-row>
      </template>
    </flip-card>
  </v-container>
</template>

<script>
import FlipCard from "@/components/common/FlipCard.vue";
import DetailedListDialog from "./DetailedListDialog.vue";
import { mapGetters } from "vuex";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";

export default {
  name: "MissingEquipmentList",
  components: {
    FlipCard,
    DetailedListDialog,
    VitrueTooltip
  },
  props: {
    equipment: {
      type: Object
    }
  },
  data() {
    return {
      detailedList: false,
      items: [
        {
          id: "backsupport",
          text: this.$t("analytics.missingEquipment.titles.lowerBack"),
          image: "chairWithBackSupport.png",
          primary: true
        },
        {
          id: "keyboard",
          text: this.$t("analytics.missingEquipment.titles.keyboard"),
          image: "keyboard.png",
          primary: false
        },
        {
          id: "mouse",
          text: this.$t("analytics.missingEquipment.titles.mouse"),
          image: "mouse.png",
          primary: false
        },
        {
          id: "keyboardMouse",
          text: this.$t("analytics.missingEquipment.titles.mouseOrKeyboard"),
          image: "mouse.png",
          primary: true
        },
        {
          id: "laptopWithStand",
          text: this.$t("analytics.missingEquipment.titles.laptopStand"),
          image: "laptop.png",
          primary: true
        },
        {
          id: "wristRest",
          text: this.$t("analytics.missingEquipment.titles.wristRest"),
          image: "strongArm.png",
          primary: false
        },
        {
          id: "seatadjustable",
          text: this.$t("analytics.missingEquipment.titles.adjustableSeat"),
          image: "chairAdjustable.png",
          primary: true
        },
        {
          id: "standingdesk",
          text: this.$t("analytics.missingEquipment.titles.standingDesk"),
          image: "standingPerson.png",
          primary: false
        },
        {
          id: "slopedsurface",
          text: this.$t("analytics.missingEquipment.titles.angleBoard"),
          image: "pageWithCurl.png",
          primary: false
        },
        {
          id: "headset",
          text: this.$t("analytics.missingEquipment.titles.headset"),
          image: "headphones.png",
          primary: false
        },
        {
          id: "computerScreen",
          text: this.$t("analytics.missingEquipment.titles.extraMonitor"),
          image: "monitor.png",
          primary: false
        },
        {
          id: "workarea",
          text: this.$t("analytics.missingEquipment.titles.missingDesk"),
          image: "table.svg",
          primary: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["anonymousResults"]),
    topPrimaryMissingItems() {
      // if no assessments returned then return empty array
      if (this.equipment.userMissingItems.length == 0) {
        return [];
      }

      // filter out mouse and keyboard from most common items list
      var arrayToSort = this.equipment.userMissingItems.filter(function (i) {
        return i.keyId !== "mouse" && i.keyId !== "keyboard";
      });

      var mouseKeyboardEntry = this.getCombinedMissingMouseAndKeyboardEntry();

      // add keyboard/mouse item if it exists and sort by descending
      if (mouseKeyboardEntry) {
        arrayToSort.push({
          associatedUsers: mouseKeyboardEntry.associatedUsers,
          keyId: "keyboardMouse"
        });
      }

      arrayToSort.sort(function (a, b) {
        return b.associatedUsers.length - a.associatedUsers.length;
      });

      // return top 3 items which are primary and have a count greater than 0
      return arrayToSort
        .filter(i => {
          return i.associatedUsers.length > 0 && this.isPrimaryItem(i);
        })
        .slice(0, 3);
    },
    missingPrimaryEquipmentExists() {
      return (
        this.topPrimaryMissingItems.length > 0 &&
        this.topPrimaryMissingItems.some(i => i.associatedUsers.length > 0)
      );
    },
    detailedListItems() {
      return this.equipment.userMissingItems.map(item => {
        let currentItem = this.items.find(i => i.id === item.keyId);
        return {
          name: currentItem.text,
          image: currentItem.image,
          number: item.associatedUsers.length,
          associatedUsers: item.associatedUsers.map(user => {
            return { name: user.name, email: user.email, value: user.value };
          })
        };
      });
    },
    detailedListWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return "80%";
      }

      return window.devicePixelRatio > 1 ? "70%" : "45%";
    }
  },
  methods: {
    getCombinedMissingMouseAndKeyboardEntry() {
      // use mouse OR keyboard (which ever highest missing) for displaying mouse/keyboard stats in top primary missing items
      var mouseItem = this.equipment.userMissingItems.find(
        i => i.keyId == "mouse"
      );
      var keyboardItem = this.equipment.userMissingItems.find(
        i => i.keyId == "keyboard"
      );

      if (!mouseItem && !keyboardItem) {
        return null;
      }

      // Take one with highest count of missing items - todo change to show more useful number when emails are linked to each item
      var moreMissingMice =
        mouseItem?.associatedUsers.length >
        keyboardItem?.associatedUsers.length;
      return moreMissingMice ? mouseItem : keyboardItem ?? mouseItem;
    },
    isPrimaryItem(item) {
      let associatedItem = this.items.find(i => {
        return i.id === item.keyId;
      });
      if (!associatedItem) {
        return false;
      }

      return associatedItem.primary;
    },
    getValue(item) {
      let associatedItem = this.items.find(i => {
        return i.id === item.keyId;
      });
      if (!associatedItem) {
        return "";
      }
      return (
        item.associatedUsers.length +
        " " +
        this.$tc(
          "analytics.missingEquipment.peopleCount",
          item.associatedUsers.length
        )
      );
    },
    getTitle(item) {
      let associatedItem = this.items.find(i => {
        return i.id === item.keyId;
      });
      if (!associatedItem) {
        return this.$t("analytics.missingEquipment.itemNotFound");
      }
      return associatedItem.text;
    },
    getEmoji(item) {
      let associatedItem = this.items.find(i => {
        return i.id === item.keyId;
      });
      let image = "sadEmoji.png";
      if (associatedItem) {
        image = associatedItem.image;
      }

      return require("@/assets/images/emojis/" + image);
    }
  }
};
</script>
