<template>
  <div>
    <v-card v-if="!showBulkEmailReminder" class="pa-5">
      <v-btn
        class="closeButton"
        icon
        @click="$emit('input', false)"
        v-if="tags.length > 0 || timeFrame !== 'All time'"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <p class="text-center text-h3 primary--text pb-4 pt-6">
        {{ $t("analytics.insufficientAssessments.title") }}
      </p>
      <v-card-text class="text-body-1 text-center font-weight-bold mt-5">
        <p v-html="$t('analytics.insufficientAssessments.subtitle')" />
        <p class="mb-0">
          <span id="tagTimeFrameText" v-html="tagTimeFrameText" />
          {{ $t("analytics.insufficientAssessments.none") }}.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" no-gutters>
          <v-col cols="10" class="my-2">
            <v-btn
              id="dashboardButton"
              @click="$router.push('/dashboard')"
              color="primary"
              rounded
              outlined
              width="100%"
              >{{ $t("buttons.dashboard") }}</v-btn
            >
          </v-col>
          <v-col cols="10" class="my-2" v-if="!disableEmails">
            <v-tooltip
              bottom
              :disabled="
                !incompleteAssessmentsLoading &&
                incompleteAssessments.length !== 0
              "
              color="transparent"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    id="bulkReminderButton"
                    @click="showBulkEmailReminder = true"
                    color="secondary"
                    rounded
                    outlined
                    width="100%"
                    :loading="incompleteAssessmentsLoading"
                    :disabled="
                      incompleteAssessments.length === 0 || disableEmails
                    "
                    >{{ $t("buttons.bulkReminder") }}</v-btn
                  >
                </div>
              </template>
              <vitrue-tooltip :message="$t('tooltips.noPendingAssessments')" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <patient-bulk-reminder-dialog
      v-if="showBulkEmailReminder"
      :incompleteAssessments="incompleteAssessments"
      @input="showBulkEmailReminder = !showBulkEmailReminder"
      @sent="$router.push('/dashboard')"
    />
  </div>
</template>

<script>
import PatientBulkReminderDialog from "../PatientBulkReminderDialog.vue";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { getAssessmentTableHistoryData } from "@/services/modules/api-queries";
import { mapGetters } from "vuex";

export default {
  name: "InsufficientAssessmentsDialog",
  props: {
    value: Boolean,
    tags: Array,
    timeFrame: String
  },
  data() {
    return {
      showBulkEmailReminder: false,
      emails: [],
      assessments: [],
      incompleteAssessmentsLoading: false
    };
  },
  components: {
    PatientBulkReminderDialog,
    VitrueTooltip
  },
  async mounted() {
    try {
      this.incompleteAssessmentsLoading = true;
      this.assessments = await getAssessmentTableHistoryData(false);
    } catch (err) {
    } finally {
      this.incompleteAssessmentsLoading = false;
    }
  },
  computed: {
    ...mapGetters(["disableEmails"]),
    tagTimeFrameText() {
      let timeFrame = this.timeFrame ?? "All time";
      let tagNames =
        this.$tc(
          "analytics.insufficientAssessments.tagsPlural",
          this.tags.length
        ) +
        " " +
        this.tags.map(x => "<b>" + x + "</b>").join(", ");
      let havePlural = this.$tc(
        "analytics.insufficientAssessments.hasPlural",
        this.tags.length
      );
      if (
        this.tags.length > 0 &&
        timeFrame !== this.$t("analytics.filter.allTime")
      ) {
        return this.$t(
          "analytics.insufficientAssessments.tagsAndTimeFrameNumberHas",
          {
            0: tagNames,
            1: timeFrame.toLowerCase(),
            2: havePlural
          }
        );
      } else if (this.tags.length > 0) {
        return this.$t("analytics.insufficientAssessments.tagHas", {
          0: tagNames,
          1: havePlural
        });
      } else if (timeFrame !== this.$t("analytics.filter.allTime")) {
        return this.$t("analytics.insufficientAssessments.timeFrameHas", {
          0: timeFrame.toLowerCase()
        });
      } else {
        return this.$t("analytics.insufficientAssessments.youCurrentlyHave");
      }
    },
    incompleteAssessments() {
      return this.assessments.filter(isIncomplete);
      function isIncomplete(item) {
        if (
          item.state !== "Complete" &&
          item.state !== "Failed" &&
          item.assessmentType === "Desk assessment"
        ) {
          return item;
        }
      }
    }
  }
};
</script>

<style scoped>
.closeButton {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
