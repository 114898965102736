<template>
  <flip-card
    :height="$vuetify.breakpoint.smAndDown ? '300px' : '230px'"
    width="100%"
  >
    <template v-slot:front>
      <div class="fill-height d-flex flex-column px-4">
        <v-row
          class="my-2"
          v-if="equipment"
          :justify="smallLayout ? 'center' : 'space-between'"
          align="center"
        >
          <v-col class="text-center text-h3" v-if="!smallLayout" cols="5"
            ><v-img contain max-height="75" :src="getCurrentlyDisplayedImage">
            </v-img>
          </v-col>
          <v-col
            class="vitrueGrey--text"
            :class="smallLayout ? 'text-center' : 'text-start'"
            :cols="smallLayout ? '10' : '7'"
          >
            <p class="text-h5">
              {{ $t("analytics.deskWellness.title") }}
            </p>
            <v-img
              v-if="smallLayout"
              class="mb-6"
              max-height="75"
              contain
              :src="getCurrentlyDisplayedImage"
            />
            <p id="percentageTextElement" class="text-body-1">
              {{ listOfItems[displayedIndex].percentageText }}
            </p>
            <p id="factTextElement" class="text-body-1">
              {{ listOfItems[displayedIndex].fact }}
            </p>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align="center"
          v-if="!equipment"
          class="fill-height"
        >
          <v-progress-circular
            id="loadingCircle"
            :size="50"
            :width="5"
            indeterminate
            color="disabled"
          >
          </v-progress-circular>
        </v-row>
      </div>
    </template>
    <template v-slot:back>
      <v-row justify="center" align="center" class="px-8 fill-height">
        <v-col cols="12">
          <p class="text-body-1">
            {{ $t("analytics.deskWellness.explanation") }}
          </p>
        </v-col>
      </v-row>
    </template>
  </flip-card>
</template>

<script>
import FlipCard from "@/components/common/FlipCard.vue";

export default {
  name: "DeskWellness",
  components: {
    FlipCard
  },
  props: {
    equipment: Object
  },
  data() {
    return {
      displayedIndex: 0
    };
  },
  computed: {
    listOfItems() {
      return [
        {
          id: "plant",
          percentageText: this.plantPercentageText,
          fact: this.$t("analytics.deskWellness.productivityFact"),
          image: "plant.svg"
        }
      ];
    },
    getCurrentlyDisplayedImage() {
      return require("@/assets/images/emojis/" +
        this.listOfItems[this.displayedIndex].image);
    },
    plantPercentageText() {
      let percentage;
      if (this.equipment.existingItems.length > 0) {
        percentage = Math.round(
          this.equipment.existingItems.find(x => x.id === "plants").percentage
        );
      } else {
        percentage = 0;
      }

      if (percentage === 0) {
        return this.$t("analytics.deskWellness.noPlants");
      }

      return percentage < 70
        ? this.$t("analytics.deskWellness.minorityPlants", { "0": percentage })
        : this.$t("analytics.deskWellness.majorityPlants", { "0": percentage });
    },
    smallLayout() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
};
</script>
