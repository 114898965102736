var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("flip-card", {
    attrs: {
      height: _vm.$vuetify.breakpoint.smAndDown ? "500px" : "100%",
      width: "100%"
    },
    scopedSlots: _vm._u([
      {
        key: "front",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "fill-height d-flex flex-column px-8" },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-center text-h5 vitrueGrey--text mt-6 px-3 mb-0"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("analytics.overallScores.title")) +
                        " "
                    )
                  ]
                ),
                _vm.summaryAnalytics
                  ? _c(
                      "v-row",
                      { attrs: { align: "center" } },
                      [
                        _vm.$vuetify.breakpoint.smAndDown
                          ? _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { justify: "center" } },
                                  [
                                    _c("nested-circular-progress", {
                                      attrs: {
                                        outer:
                                          _vm.summaryAnalytics.maximumDeskScore,
                                        middle:
                                          _vm.summaryAnalytics.averageDeskScore,
                                        inner:
                                          _vm.summaryAnalytics.minimumDeskScore
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-col",
                          { attrs: { cols: _vm.scoreContainerColumns } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "max-width": "200",
                                      "nudge-top": "50",
                                      "content-class": "text-center",
                                      "open-on-click": "",
                                      color: "transparent"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-col",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: {
                                                        cols: _vm.scoreColumns
                                                      }
                                                    },
                                                    "v-col",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "p",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-h4 vitruePaleGreen--text mb-0"
                                                        },
                                                        "p",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.summaryAnalytics
                                                              .maximumDeskScore
                                                          ) +
                                                          "% "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "body-1 mb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "analytics.overallScores.highScore"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1236782299
                                    )
                                  },
                                  [
                                    _c("vitrue-tooltip", {
                                      attrs: {
                                        message: _vm.$t(
                                          "analytics.overallScores.highScoreDescription"
                                        )
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "max-width": "200",
                                      "nudge-top": "50",
                                      "content-class": "text-center",
                                      "open-on-click": "",
                                      color: "transparent"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-col",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: {
                                                        cols: _vm.scoreColumns
                                                      }
                                                    },
                                                    "v-col",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-h4 primary--text mb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.summaryAnalytics
                                                              .averageDeskScore
                                                          ) +
                                                          "% "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "body-1 mb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "analytics.overallScores.averageScore"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1330993874
                                    )
                                  },
                                  [
                                    _c("vitrue-tooltip", {
                                      attrs: {
                                        message: _vm.$t(
                                          "analytics.overallScores.averageScoreDescription"
                                        )
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "max-width": "200",
                                      "nudge-top": "50",
                                      "content-class": "text-center",
                                      "open-on-click": "",
                                      color: "transparent"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-col",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: {
                                                        cols: _vm.scoreColumns
                                                      }
                                                    },
                                                    "v-col",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-h4 vitrueOrange--text mb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.summaryAnalytics
                                                              .minimumDeskScore
                                                          ) +
                                                          "% "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "body-1 mb-0"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "analytics.overallScores.lowestScore"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      486828135
                                    )
                                  },
                                  [
                                    _c("vitrue-tooltip", {
                                      attrs: {
                                        message: _vm.$t(
                                          "analytics.overallScores.lowestScoreDescription"
                                        )
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.$vuetify.breakpoint.smAndDown
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-h4 primary--text mb-0"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.summaryAnalytics
                                                    .totalAssessmentsComplete
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "body-1 mb-0" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "analytics.overallScores.assessmentsComplete"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        !_vm.$vuetify.breakpoint.smAndDown
                          ? _c(
                              "v-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "6" }
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          "align-self": "end"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { justify: "center" } },
                                          [
                                            _c("nested-circular-progress", {
                                              attrs: {
                                                outer:
                                                  _vm.summaryAnalytics
                                                    .maximumDeskScore,
                                                middle:
                                                  _vm.summaryAnalytics
                                                    .averageDeskScore,
                                                inner:
                                                  _vm.summaryAnalytics
                                                    .minimumDeskScore
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          "align-self": "end"
                                        }
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-h4 primary--text mb-0"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.summaryAnalytics
                                                    .totalAssessmentsComplete
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "body-1 mb-0" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "analytics.overallScores.complete"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.summaryAnalytics
                  ? _c(
                      "v-row",
                      {
                        staticClass: "fill-height",
                        attrs: { justify: "center", align: "center" }
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            id: "loadingCircle",
                            size: 50,
                            width: 5,
                            indeterminate: "",
                            color: "disabled"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "back",
        fn: function() {
          return [
            _c(
              "v-row",
              {
                staticClass: "px-8 fill-height",
                attrs: { justify: "center", align: "center" }
              },
              [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c("p", {
                    staticClass: "text-body-1",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("analytics.overallScores.explanation")
                      )
                    }
                  })
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }