var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("flip-card", {
    attrs: {
      height: _vm.$vuetify.breakpoint.smAndDown ? "300px" : "230px",
      width: "100%"
    },
    scopedSlots: _vm._u([
      {
        key: "front",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "fill-height d-flex flex-column px-4" },
              [
                _vm.equipment
                  ? _c(
                      "v-row",
                      {
                        staticClass: "my-2",
                        attrs: {
                          justify: _vm.smallLayout ? "center" : "space-between",
                          align: "center"
                        }
                      },
                      [
                        !_vm.smallLayout
                          ? _c(
                              "v-col",
                              {
                                staticClass: "text-center text-h3",
                                attrs: { cols: "5" }
                              },
                              [
                                _c("v-img", {
                                  attrs: {
                                    contain: "",
                                    "max-height": "75",
                                    src: _vm.getCurrentlyDisplayedImage
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-col",
                          {
                            staticClass: "vitrueGrey--text",
                            class: _vm.smallLayout
                              ? "text-center"
                              : "text-start",
                            attrs: { cols: _vm.smallLayout ? "10" : "7" }
                          },
                          [
                            _c("p", { staticClass: "text-h5" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("analytics.deskWellness.title")
                                  ) +
                                  " "
                              )
                            ]),
                            _vm.smallLayout
                              ? _c("v-img", {
                                  staticClass: "mb-6",
                                  attrs: {
                                    "max-height": "75",
                                    contain: "",
                                    src: _vm.getCurrentlyDisplayedImage
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "p",
                              {
                                staticClass: "text-body-1",
                                attrs: { id: "percentageTextElement" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.listOfItems[_vm.displayedIndex]
                                        .percentageText
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "text-body-1",
                                attrs: { id: "factTextElement" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.listOfItems[_vm.displayedIndex].fact
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.equipment
                  ? _c(
                      "v-row",
                      {
                        staticClass: "fill-height",
                        attrs: { justify: "center", align: "center" }
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            id: "loadingCircle",
                            size: 50,
                            width: 5,
                            indeterminate: "",
                            color: "disabled"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "back",
        fn: function() {
          return [
            _c(
              "v-row",
              {
                staticClass: "px-8 fill-height",
                attrs: { justify: "center", align: "center" }
              },
              [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c("p", { staticClass: "text-body-1" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("analytics.deskWellness.explanation")) +
                        " "
                    )
                  ])
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }