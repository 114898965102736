var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-0", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "my-4",
          attrs: { "no-gutters": "", justify: "space-between", align: "end" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-4 d-flex text-body-2",
                  attrs: { color: "#EFF6FF" }
                },
                [
                  _c("v-icon", { staticClass: "mr-4" }, [
                    _vm._v("mdi-lightbulb-outline")
                  ]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("insightsPage.informationText")))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "5" } },
            [
              _c(
                "v-row",
                { attrs: { align: "end" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "11" } },
                    [
                      _c("v-text-field", {
                        staticStyle: {
                          "min-width": "15vw",
                          "padding-right": "2vw"
                        },
                        attrs: {
                          label: _vm.team
                            ? _vm.$t("insightsPage.table.searchLabel.team")
                            : _vm.$t(
                                "insightsPage.table.searchLabel.individual"
                              ),
                          "append-icon": "mdi-magnify",
                          "single-line": "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "1" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "", color: "transparent" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "div",
                                    _vm._g(_vm._b({}, "div", attrs, false), on),
                                    [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "primary",
                                                loading: _vm.exportingCsv
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.exportInsights()
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "primary" } },
                                            [_vm._v("mdi-download")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("vitrue-tooltip", {
                            attrs: {
                              message: _vm.$t("tooltips.downloadInsights")
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.team
        ? _c(
            "v-row",
            { staticClass: "fill-height", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("insights-table", {
                    attrs: {
                      id: "incompleteTeamActions",
                      actions: _vm.incompleteTeamActions,
                      headers: _vm.teamHeaders,
                      teams: _vm.team,
                      searchText: _vm.search
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-h5 mt-12", attrs: { cols: "12" } },
                [_vm._v(_vm._s(_vm.$t("insightsPage.titles.complete")))]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("insights-table", {
                    attrs: {
                      id: "completeTeamActions",
                      actions: _vm.completeTeamActions,
                      headers: _vm.teamHeaders,
                      teams: _vm.team,
                      grayOut: "",
                      searchText: _vm.search
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            { staticClass: "fill-height", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("insights-table", {
                    attrs: {
                      actions: _vm.incompleteIndividualActions,
                      headers: _vm.individualHeaders,
                      teams: _vm.team,
                      searchText: _vm.search
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "text-h5 mt-12", attrs: { cols: "12" } },
                [_vm._v(_vm._s(_vm.$t("insightsPage.titles.complete")))]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("insights-table", {
                    attrs: {
                      actions: _vm.completeIndividualActions,
                      headers: _vm.individualHeaders,
                      teams: _vm.team,
                      searchText: _vm.search,
                      grayOut: ""
                    }
                  })
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }