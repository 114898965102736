<template>
  <v-row justify="center" no-gutters>
    <v-col cols="12"
      ><svg width="100%" viewBox="0 0 160 160" class="donut-chart">
        <g v-for="(value, index) in values" :key="index">
          <circle
            :cx="cx"
            :cy="cy"
            :r="radius"
            :stroke="colors[index]"
            :stroke-width="strokeWidth"
            :stroke-dasharray="adjustedCircumference"
            :stroke-dashoffset="calculateStrokeDashOffset(value, circumference)"
            fill="transparent"
            :transform="returnCircleTransformValue(index)"
          />
        </g>
      </svg>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      angleOffset: -90,
      cx: 80,
      cy: 80,
      radius: 60,
      strokeWidth: 20
    };
  },
  props: {
    values: Array,
    colors: Array,
    circumferenceGapValue: {
      type: Number,
      default: 0
    }
  },
  computed: {
    adjustedCircumference() {
      // adjust the circumference to add small white gaps (negating circumference will increase gaps)
      return this.circumference - this.circumferenceGapValue;
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    dataTotal() {
      return this.values.reduce((acc, val) => acc + val);
    },
    chartData() {
      let arrayToReturn = [];
      this.values.forEach((dataVal, index) => {
        const { x, y } = this.calculateTextCoords(dataVal, this.angleOffset);
        // start at -90deg so that the largest segment is perpendicular to top
        const data = {
          degrees: this.angleOffset,
          textX: x,
          textY: y
        };
        arrayToReturn.push(data);
        this.angleOffset =
          this.dataPercentage(dataVal) * 360 + this.angleOffset;
      });
      return arrayToReturn;
    }
  },
  methods: {
    calculateStrokeDashOffset(dataVal, circumference) {
      const strokeDiff = this.dataPercentage(dataVal) * circumference;
      return circumference - strokeDiff;
    },
    calculateTextCoords(dataVal, angleOffset) {
      const angle = (this.dataPercentage(dataVal) * 360) / 2 + angleOffset;
      const radians = this.degreesToRadians(angle);

      const textCoords = {
        x: this.radius * Math.cos(radians) + this.cx,
        y: this.radius * Math.sin(radians) + this.cy
      };
      return textCoords;
    },
    degreesToRadians(angle) {
      return angle * (Math.PI / 180);
    },
    dataPercentage(dataVal) {
      return dataVal / this.dataTotal;
    },
    percentageString(dataVal) {
      return `${Math.round(this.dataPercentage(dataVal) * 100)}%`;
    },
    returnCircleTransformValue(index) {
      return `rotate(${this.chartData[index].degrees}, ${this.cx}, ${this.cy})`;
    },
    segmentBigEnough(dataVal) {
      return Math.round(this.dataPercentage(dataVal) * 100) > 5;
    }
  }
};
</script>
