var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-5" },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { icon: "", color: "black" },
          on: {
            click: function($event) {
              return _vm.$emit("input", false)
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-close")])],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "p",
              {
                staticClass: "text-center text-h4 primary--text",
                attrs: { id: "titleText" }
              },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            )
          ]),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-0 removeRowHover",
                staticStyle: { height: "400px", "overflow-y": "auto" },
                attrs: {
                  headers: _vm.headers,
                  items: _vm.listOfItems,
                  expanded: _vm.expanded,
                  "item-key": "name",
                  "show-expand": "",
                  dense: "",
                  "hide-default-header": "",
                  "hide-default-footer": "",
                  "disable-filtering": "",
                  "disable-pagination": "",
                  "disable-sort": "",
                  "mobile-breakpoint": 0
                },
                on: {
                  "update:expanded": function($event) {
                    _vm.expanded = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function(ref) {
                      var headers = ref.props.headers
                      return [
                        _c("thead", [
                          _c("tr", { attrs: { colspan: headers.length } }, [
                            _c("th", { staticStyle: { width: "1%" } }),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "text-body-2 font-weight-medium black--text",
                                attrs: { id: "titleHeader" }
                              },
                              [_vm._v(" " + _vm._s(_vm.title) + " ")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass:
                                  "text-body-2 font-weight-medium black--text",
                                attrs: { id: "employeeHeader" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "analytics.detailedList.table.employees"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.data-table-expand",
                    fn: function(ref) {
                      var item = ref.item
                      var expand = ref.expand
                      var isExpanded = ref.isExpanded
                      return [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              color: "primary",
                              disabled: item.number === 0
                            },
                            on: {
                              click: function($event) {
                                return expand(!isExpanded)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                isExpanded
                                  ? "mdi-chevron-right mdi-rotate-90"
                                  : "mdi-chevron-right"
                              )
                            )
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.image
                          ? _c("v-img", {
                              staticClass: "mr-2",
                              staticStyle: { float: "left" },
                              attrs: {
                                src: require("@/assets/images/emojis/" +
                                  item.image),
                                alt: item.image,
                                height: "20px",
                                width: "20px"
                              }
                            })
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            staticClass: "text-body-2 vitrueGrey--text",
                            attrs: {
                              id: item.name.replace(" ", "") + "-" + "ItemText"
                            }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.number",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "p",
                          {
                            staticClass: "my-0 text-body-2 vitrueGrey--text",
                            attrs: {
                              id:
                                item.name.replace(" ", "") + "-" + "ItemNumber"
                            }
                          },
                          [_vm._v(" " + _vm._s(item.number) + " ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm.expanded.includes(item)
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  id:
                                    item.name.replace(" ", "") +
                                    "-" +
                                    "ItemCopy",
                                  disabled: item.number === 0,
                                  icon: "",
                                  small: "",
                                  width: "20px",
                                  height: "20px"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.copyUsersToClipboard(item)
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-content-copy")])],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "expanded-item",
                    fn: function(ref) {
                      var headers = ref.headers
                      var item = ref.item
                      return [
                        _c(
                          "td",
                          {
                            staticClass: "pa-0 pb-3",
                            staticStyle: { "background-color": "white" },
                            attrs: { colspan: headers.length }
                          },
                          [
                            _c("v-simple-table", {
                              attrs: {
                                dense: "",
                                id:
                                  item.name.replace(" ", "") +
                                  "-" +
                                  "InnerTable"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function() {
                                      return [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "text-caption black--text",
                                                attrs: { width: "10%" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.valueHeader
                                                        ? _vm.valueHeader
                                                        : ""
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "text-caption black--text",
                                                attrs: {
                                                  width: _vm.$vuetify.breakpoint
                                                    .mdAndUp
                                                    ? "50%"
                                                    : "0%"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "analytics.detailedList.table.name"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "text-caption black--text",
                                                attrs: { width: "40%" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "analytics.detailedList.table.email"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.SortedAssociatedUsers(
                                              item.associatedUsers
                                            ),
                                            function(user) {
                                              return _c(
                                                "tr",
                                                { key: user.email },
                                                [
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-caption vitrueGrey--text",
                                                      staticStyle: {
                                                        "font-size": "10px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            user.value
                                                              ? user.value
                                                              : ""
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-caption vitrueGrey--text inner-table-name-wrap data-hj-suppress",
                                                      staticStyle: {
                                                        "font-size": "10px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(user.name) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-caption vitrueGrey--text inner-table-name-wrap data-hj-suppress",
                                                      staticStyle: {
                                                        "font-size": "10px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(user.email) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: {
                absolute: "",
                centered: "",
                color: "secondary",
                timeout: "1500",
                transition: "fade-transition",
                "content-class": "text-center"
              },
              model: {
                value: _vm.copyUsersSuccessful,
                callback: function($$v) {
                  _vm.copyUsersSuccessful = $$v
                },
                expression: "copyUsersSuccessful"
              }
            },
            [_vm._v(_vm._s(_vm.$t("analytics.detailedList.copySuccess")))]
          ),
          _c(
            "v-snackbar",
            {
              attrs: {
                absolute: "",
                centered: "",
                color: "error",
                timeout: "1500",
                transition: "fade-transition",
                "content-class": "text-center"
              },
              model: {
                value: _vm.copyUsersFailed,
                callback: function($$v) {
                  _vm.copyUsersFailed = $$v
                },
                expression: "copyUsersFailed"
              }
            },
            [_vm._v(_vm._s(_vm.$t("analytics.detailedList.copyError")))]
          ),
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    "x-large": "",
                    rounded: "",
                    outlined: "",
                    width: "100%"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("input", false)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }