<template>
  <v-container fluid class="pa-0">
    <v-dialog
      persistent
      v-model="showMissingPeople"
      v-if="showMissingPeople"
      :width="peopleListWidth"
    >
      <simple-list-dialog
        v-model="showMissingPeople"
        :headers="missingPeopleHeaders"
        :listOfItems="activeMissingPeopleList"
      />
    </v-dialog>

    <v-data-table
      class="elevation-0 removeRowHover"
      :loading="dataTableLoading"
      :headers="headers"
      :items="actions"
      :items-per-page="numberOfActionsPerPage"
      :footer-props="{ 'items-per-page-options': [3, 5, 10, -1] }"
      :search="searchText"
      :custom-filter="customFilter"
      disable-sort
      :mobile-breakpoint="0"
    >
      <!-- Template for impact chips -->
      <template v-slot:item.impact="{ item }" v-if="teams">
        <v-chip
          id="teamImpactChip"
          :color="getImpactColor(item.score)"
          dark
          class="text-body-1"
          style="width: 100%"
          small
        >
          <span class="text-center" style="width: 100%">{{
            getImpactText(item.score)
          }}</span>
        </v-chip>
      </template>

      <!-- Template for issue column -->
      <template v-slot:item.issue="{ item }" v-if="teams">
        <span id="teamIssueText" class="text-body-2"
          >{{ getIssueText(item.actionName) }} <br />
          <v-tooltip :disabled="!anonymousResults" bottom color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" class="d-flex">
                <v-btn
                  plain
                  small
                  color="blue"
                  text
                  class="pa-0 ma-0"
                  @click="showPeople(item)"
                  :disabled="anonymousResults"
                >
                  {{
                    $tc(
                      "insightsPage.table.listOfPeopleText",
                      item.insights.length
                    )
                  }}</v-btn
                >
              </div>
            </template>
            <vitrue-tooltip :message="$t('anonymousResults.tooltip')" />
          </v-tooltip>
        </span>
      </template>

      <!-- Template for action-->
      <template v-slot:item.action="{ item }" v-if="teams">
        <span class="text-body-2">
          <v-tooltip bottom color="transparent">
            <vitrue-tooltip :message="getTooltipText(item.insights)" />

            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                id="teamActionText"
                style="cursor: default"
              >
                <v-icon class="mr-2" color="primary"
                  >mdi-information-outline</v-icon
                >
                {{ getActionText(item.actionName) }}
              </div>
            </template>
          </v-tooltip>
        </span>
      </template>

      <!-- Template for benefit chips-->
      <template v-slot:item.benefits="{ item }" v-if="teams">
        <div
          id="teamBenefitChip"
          v-for="(benefit, i) in get2MostCommonBenefits(item.insights)"
          :key="i"
          class="my-2"
        >
          <v-chip
            :color="getBenefitColor(benefit)"
            dark
            outlined
            class="text-body-1 my-1"
            style="width: 100%"
            small
          >
            <span class="text-center" style="width: 100%">{{
              getBenefitText(benefit)
            }}</span>
          </v-chip>
        </div>
      </template>

      <!-- template for checkbox -->
      <template v-slot:item.done="{ item }" v-if="teams">
        <v-simple-checkbox
          id="teamCheckbox"
          :ripple="false"
          v-model="item.complete"
          color="primary"
          @click="saveActionDone(item)"
        ></v-simple-checkbox>
      </template>

      <!-- template for note column items -->
      <template v-slot:item.note="{ item }" v-if="teams">
        <insights-note
          :key="item.actionName"
          id="teamNote"
          class="my-1"
          v-model="item.teamNote"
          @saveText="saveNote(item)"
        />
      </template>

      <!-- Template for body when passing through individuals results -->
      <template v-if="!teams" v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, i) in items" :key="i" class="text-body-2">
            <td class="py-3" style="vertical-align: top">
              <v-chip
                id="individualImpactChip"
                :color="
                  getImpactColor(getIndividualAverageScore(item.insights))
                "
                dark
                class="text-body-1"
                style="width: 100%"
                small
              >
                <span class="text-center" style="width: 100%">{{
                  getImpactText(getIndividualAverageScore(item.insights))
                }}</span>
              </v-chip>
            </td>
            <td class="pb-4 pt-5 no-wrap" style="vertical-align: top">
              <span id="individualDetails" class="data-hj-suppress"
                >{{ item.insights[0].name }} <br v-if="item.insights[0].name" />
                <a :href="'mailto:' + item.insights[0].email.value">{{
                  item.insights[0].email.value
                }}</a></span
              >
            </td>
            <td class="pb-4 pt-5" style="vertical-align: top">
              <tr
                v-for="(insight, i) in individualActionsToShow(item)"
                :key="i"
                :style="individualActionRowHeightStyle(insight)"
              >
                <td>
                  <v-tooltip bottom color="transparent">
                    <vitrue-tooltip :message="getTooltipText([insight])" />

                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        id="individualActionText"
                        :class="
                          insight.complete ? 'text-decoration-line-through' : ''
                        "
                        style="cursor: default"
                      >
                        <v-icon class="mr-2" color="primary"
                          >mdi-information-outline</v-icon
                        >
                        {{ getActionText(insight.actionName) }}
                      </div>
                    </template>
                  </v-tooltip>
                </td>
              </tr>

              <tr
                v-if="
                  item.insights.length > defaultNumberOfIndividualActionsToShow
                "
              >
                <td>
                  <v-btn
                    class="pa-0 ma-0"
                    plain
                    small
                    color="primary"
                    text
                    @click="item.showAll = !item.showAll"
                  >
                    <v-icon>{{
                      item.showAll ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                    <span class="text-body-2 pa-0 ma-0">
                      {{
                        item.showAll
                          ? $t("buttons.showLess")
                          : $t("buttons.showMore")
                      }}
                    </span></v-btn
                  >
                </td>
              </tr>
            </td>

            <td class="py-3" style="vertical-align: top">
              <tr
                v-for="(insight, i) in individualActionsToShow(item)"
                :key="i"
                :style="individualActionRowHeightStyle(insight)"
              >
                <td>
                  <div v-for="(benefit, i) in insight.benefits" :key="i">
                    <v-chip
                      id="individualBenefitChip"
                      :color="getBenefitColor(benefit)"
                      dark
                      outlined
                      class="text-body-1 mb-2"
                      style="width: 100%"
                      small
                    >
                      <span class="text-center" style="width: 100%">{{
                        getBenefitText(benefit)
                      }}</span>
                    </v-chip>
                  </div>
                </td>
              </tr>
            </td>

            <td class="py-4 justify-center" style="vertical-align: top">
              <tr
                v-for="(insight, i) in individualActionsToShow(item)"
                class="d-flex justify-center align-start"
                :key="i"
                :style="individualActionRowHeightStyle(insight)"
              >
                <td>
                  <v-simple-checkbox
                    :ripple="false"
                    v-model="insight.complete"
                    color="primary"
                    @click="saveActionDone(insight)"
                    style="padding-top: 1px"
                  ></v-simple-checkbox>
                </td>
              </tr>
            </td>

            <td class="py-4 pt-4" style="vertical-align: top">
              <tr
                v-for="(insight, i) in individualActionsToShow(item)"
                :key="i"
                :style="individualActionRowHeightStyle(insight)"
              >
                <td style="vertical-align: center">
                  <insights-note
                    :key="insight.id"
                    v-model="insight.individualNote"
                    bumpUp
                    @saveText="saveNote(insight)"
                  />
                </td>
              </tr>
            </td>
          </tr>
        </tbody>
      </template> </v-data-table
  ></v-container>
</template>

<script>
import SimpleListDialog from "@/components/common/SimpleListDialog.vue";
import InsightsTextServices from "@/services/analytics/insights-text-services";
import { updateInsightsCompletedAt, updateNotes } from "@/customApi";
import InsightsNote from "./InsightsNote.vue";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { mapGetters } from "vuex";
export default {
  name: "InsightsTable",
  mixins: [InsightsTextServices],
  components: {
    SimpleListDialog,
    InsightsNote,
    VitrueTooltip
  },
  props: {
    teams: Boolean,
    headers: Array,
    actions: Array,
    grayOut: Boolean,
    searchText: String
  },
  data() {
    return {
      dataTableLoading: false,
      showMissingPeople: false,
      activeMissingPeopleList: [],
      missingPeopleHeaders: [
        {
          text: this.$t("textFields.name"),
          value: "name",
          width: "50%"
        },
        {
          text: this.$t("textFields.email"),
          value: "email",
          width: "50%"
        }
      ],
      numberOfActionsPerPage: this.teams ? 5 : 3,
      defaultNumberOfIndividualActionsToShow: 3
    };
  },
  methods: {
    async saveActionDone(action) {
      let ids = action.insights ? action.insights.map(x => x.id) : [action.id];
      try {
        await updateInsightsCompletedAt(
          ids,
          action.complete ? "setComplete" : "setNotComplete"
        );
      } catch (err) {
        action.complete = !action.complete;
      }
    },
    async saveNote(action) {
      let message = action.teamNote ?? action.individualNote;
      let ids = action.insights ? action.insights.map(x => x.id) : [action.id];
      let notesToUpdate = {
        ids: ids,
        message: message,
        updateType: action.insights ? "team" : "individual"
      };
      try {
        await updateNotes(notesToUpdate);
      } catch (err) {}
    },
    showPeople(action) {
      this.showMissingPeople = true;
      this.activeMissingPeopleList = action.insights.map(x => {
        return { name: x.name, email: x.email.value };
      });
    },
    individualActionRowHeightStyle(action) {
      let rowHeight = 55;
      if (action.benefits.length > 1) {
        let extraHeight = (action.benefits.length - 1) * 35;
        return "height: " + (extraHeight + rowHeight) + "px;";
      }
      return "height: " + rowHeight + "px;";
    },
    getImpactColor(score) {
      if (this.grayOut) {
        return this.$vuetify.theme.currentTheme.vitrueGrey;
      }
      if (score > 7) {
        return this.$vuetify.theme.currentTheme.vitrueRed;
      } else if (score > 4) {
        return this.$vuetify.theme.currentTheme.vitrueOrange;
      }
      return this.$vuetify.theme.currentTheme.vitrueYellow;
    },
    getBenefitColor(benefit) {
      if (this.grayOut) {
        return this.$vuetify.theme.currentTheme.vitrueGrey;
      }
      if (benefit === "reducePain") {
        return this.$vuetify.theme.currentTheme.vitrueRed;
      } else if (benefit === "improveWellbeing") {
        return this.$vuetify.theme.currentTheme.vitrueYellow;
      } else if (benefit === "preventProductivityLoss") {
        return this.$vuetify.theme.currentTheme.vitrueOrange;
      }
      return this.$vuetify.theme.currentTheme.vitruePaleGreen;
    },
    get2MostCommonBenefits(insights) {
      let benefits = insights.flatMap(x => x.benefits);
      let uniqueBenefits = [...new Set(benefits)];
      let benefitCounts = uniqueBenefits.map(x => {
        return {
          benefit: x,
          count: benefits.filter(v => v === x).length
        };
      });
      benefitCounts.sort((a, b) => {
        return a.count > b.count ? -1 : 1;
      });
      return benefitCounts.slice(0, 2).map(x => x.benefit);
    },
    customFilter(value, search, item) {
      var searchLowerCase = search.toLowerCase();
      if (this.teams) {
        return (
          this.getActionText(item.actionName)
            .toLowerCase()
            .includes(searchLowerCase) ||
          this.getImpactText(item.score)
            .toLowerCase()
            .includes(searchLowerCase) ||
          this.getIssueText(item.actionName)
            .toLowerCase()
            .includes(searchLowerCase) ||
          this.get2MostCommonBenefits(item.insights).some(b =>
            this.getBenefitText(b).toLowerCase().includes(searchLowerCase)
          ) ||
          (item.insights[0].teamNote &&
            item.insights[0].teamNote.toLowerCase().includes(searchLowerCase))
        );
      }

      return item.insights[0].name
        ? item.insights[0].name.toLowerCase().includes(searchLowerCase) ||
            item.insights[0].email.value.includes(searchLowerCase)
        : item.insights[0].email.value.includes(searchLowerCase);
    },
    individualActionsToShow(item) {
      if (item.showAll) {
        return item.insights;
      }
      return item.insights.slice(
        0,
        this.defaultNumberOfIndividualActionsToShow
      );
    }
  },
  computed: {
    ...mapGetters(["anonymousResults"]),
    peopleListWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "80%";
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return "40%";
      }

      return window.devicePixelRatio > 1 ? "70%" : "40%";
    }
  }
};
</script>

<style scoped>
/* Prevent hover over effect on v-chips */
::v-deep .v-chip:hover:before {
  opacity: 0;
}

/* set chip content style so we can center text in v-chips */
::v-deep .v-chip__content {
  width: 100%;
}

::v-deep .v-input__append-inner {
  height: 80%;
}

::v-deep .v-textarea {
  width: 300px;
}

::v-deep tr:hover:not(.v-data-table__expanded__content) {
  background: #ffffff !important;
}

.no-wrap {
  overflow: hidden;
  white-space: nowrap;
  max-width: 75px;
  text-overflow: ellipsis;
}
</style>
